import { Injectable } from '@angular/core';
import { FollowUpQuestionsService } from '../Services/follow-up-questions.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  follow_up_questions,
  jobDetail,
  application,
  additional_insights,
} from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class FollowUpQuestionsManagerService {
  public questions: follow_up_questions[] = [];
  public additional_insights: additional_insights[] = [];
  public questionsOnJob: follow_up_questions[] = [];
  public isLoading: boolean = false;
  public isSubmiting: boolean = false;
  isVisibleQuestionsModal: boolean = false;
  isVisibleJobQuestionsModal: boolean = false;
  isVisbileConfirmSubmit: boolean = false;
  successModal: boolean = false;
  statusFollowUpQuestions: boolean = false;
  has_answers_submitted: boolean = false;
  public jobDetailData!: jobDetail;
  public application!: application;
  public isAllAnswered: boolean = false;
  constructor(
    public followUpServices: FollowUpQuestionsService,
    private notification: NzNotificationService
  ) {}
  public getFollowUpQuestions(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.followUpServices.getFollowUpQuestions(id).subscribe({
        next: (c: any) => {
          let questionsList = c.response.follow_up_questions.reduce(
            (acc: any, question: any) => {
              let categoryIndex = acc.findIndex(
                (item: any) => item.category === question.category
              );
              if (categoryIndex === -1) {
                acc.push({
                  category: question.category,
                  questions: [
                    {
                      _id: question._id,
                      question: question.question,
                      answer: question.answer ? question.answer : [],
                      type: question.type,
                      options: question.options,
                      config: question.config
                        ? question.config
                        : { is_multi_select: false, is_user_entered: false },
                      custom: question.custom ? question.custom : '',
                    },
                  ],
                });
              } else {
                acc[categoryIndex].questions.push({
                  _id: question._id,
                  question: question.question,
                  answer: question.answer ? question.answer : [],
                  type: question.type,
                  options: question.options,
                  config: question.config
                    ? question.config
                    : { is_multi_select: false, is_user_entered: false },
                  custom: question.custom ? question.custom : '',
                });
              }
              return acc;
            },
            []
          );
          this.questions = questionsList;
          this.jobDetailData = c.response.job_details;
          this.application = c.response.application;
          this.additional_insights = c.response.additional_insights;
          this.has_answers_submitted = c.response.has_answers_submitted;
          this.isLoading = false;
          //this.checkAllQuestionsAnswered();
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }

  // private checkAllQuestionsAnswered(): void {
  //   console.log('checking', this.has_answers_submitted);
  //   //console.log(this.questions);
  //   let additionalInsightsAnswered = false;
  //   let questionsAnswered = false;
  //   questionsAnswered = this.questions.some((category: any) =>
  //     category.questions.some(
  //       (question: any) => question.answer && question.answer.length > 0
  //     )
  //   );

  //   // Check if all additional insights are answered
  //   if (this.additional_insights && this.additional_insights.length > 0) {
  //     additionalInsightsAnswered = this.additional_insights.every(
  //       (insight: any) => insight.answers && insight.answers.length > 0
  //     );
  //   }

  //   // Update isAllAnswered based on both checks
  //   this.isAllAnswered = questionsAnswered || additionalInsightsAnswered;
  //   // this.isAllAnswered = false;
  // }

  public submitFollowUpQuestions(data: any, id: string) {
    this.isSubmiting = true;
    this.followUpServices.submitFollowUpQuestions(data, id).subscribe({
      next: (c: any) => {
        this.isSubmiting = false;
        this.isVisibleQuestionsModal = false;
        this.successModal = true;
        this.isVisbileConfirmSubmit = false;
        this.getFollowUpQuestions(id);
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isSubmiting = false;
      },
    });
  }

  public getJobFollowUpQuestions(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.questionsOnJob = [];
      this.followUpServices.getJobFollowUpQuestions(id).subscribe({
        next: (c: any) => {
          if (c.response.follow_up_questions != null) {
            // console.log(c.response.follow_up_questions);
            this.questionsOnJob = c.response.follow_up_questions;
            resolve();
          }

          this.isLoading = false;
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public saveJobFollowUpQuestions(id: string, formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.followUpServices.saveJobFollowUpQuestions(id, formData).subscribe({
        next: (c: any) => {
          this.isLoading = false;
          this.getJobFollowUpQuestions(id);
          this.notification.create(
            'success',
            'Done',
            'Questions have been updated successfully!'
          );
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public updateJobFollowUpQuestion(id: string, formData: any): void {
    this.isLoading = true;
    this.followUpServices.updateJobFollowUQuestion(id, formData).subscribe({
      next: (c: any) => {
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public getStatusFollowUpQuestions(id: string): void {
    this.isLoading = true;
    this.followUpServices.getStatusFollowUpQuestions(id).subscribe({
      next: (c: any) => {
        this.statusFollowUpQuestions =
          Object(c.response)['follow_up_questions'] === 'false' ? false : true;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public sentStatusFollowUpQuestions(formData: any, id: string): void {
    this.isLoading = true;
    this.followUpServices.sentStatusFollowUpQuestions(formData, id).subscribe({
      next: (c: any) => {
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
}
