import { Component } from '@angular/core';
import { CandidatesManagerService } from 'src/app/Managers/candidates-manager.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrl: './candidates.component.scss',
})
export class CandidatesComponent {
  filterForm!: FormGroup;
  searchFormCandidate!: UntypedFormGroup;
  scoreOptions = Array.from({ length: 21 }, (_, i) => i * 5);
  defaultValues = {
    jobs: [],
    application_stages: [],
    multiple_jobs: '',
    job_status: '',
    min_score: '',
    max_score: '',
    bookmarked: false,
    search: '',
  };
  constructor(
    public candidateMgr: CandidatesManagerService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  loadMoreCandidate(pageIndex: number): void {
    this.candidateMgr.page = pageIndex;
    this.candidateMgr.getCandidatesSearch(pageIndex, this.defaultValues);
  }

  selectedCandidate(user: any) {
    this.candidateMgr.selectedCandidate = user;
  }
  goToCandidatePage(id: any) {
    id && this.router.navigate(['candidates', id]);
  }

  bookmarkCandidate(id: string, isBookmarked: boolean): void {
    console.log(isBookmarked);
    if (isBookmarked === true) {
      this.candidateMgr.removeBookmarkCandidate(id);
    } else {
      this.candidateMgr.addBookmarkCandidate(id);
    }
  }
  removeBookmarked(id: string, isBookmarked: boolean): void {
    this.candidateMgr.removeBookmarked(id);
  }
  onSubmitFilterForm(): void {
    const formValue = { ...this.filterForm.value };
    formValue.multiple_jobs = formValue.multiple_jobs === 'yes';
    this.defaultValues = formValue;
    this.candidateMgr.getCandidatesSearch(1, formValue);
  }
  onResetFilterForm(): void {
    this.filterForm.reset();
    this.candidateMgr.getCandidatesSearch(1, '');
  }
  ngOnInit(): void {
    this.filterForm = this.fb.group({
      jobs: [this.defaultValues.jobs],
      application_stages: [this.defaultValues.application_stages],
      multiple_jobs: [this.defaultValues.multiple_jobs],
      job_status: [this.defaultValues.job_status],
      min_score: [this.defaultValues.min_score],
      max_score: [this.defaultValues.max_score],
      bookmarked: [this.defaultValues.bookmarked],
      search: [this.defaultValues.search],
    });
    this.candidateMgr.page = 1;
    this.candidateMgr.getCandidatesFilters();
    this.candidateMgr.getCandidatesSearch(1, '');
    this.searchFormCandidate = this.fb.group({
      search: [''],
    });
  }
  ngOnDestroy() {
    this.candidateMgr.page = 1;
    this.candidateMgr.searchCandidates = '';
  }
}
