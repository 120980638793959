import { Component, HostListener } from '@angular/core';
import { IntegrationsManagerService } from 'src/app/Managers/integrations-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';

@Component({
  selector: 'app-job-processing',
  templateUrl: './job-processing.component.html',
  styleUrl: './job-processing.component.scss',
})
export class JobProcessingComponent {
  isDropdownOpen: { [key: string]: boolean } = {};
  constructor(
    public integrationsMgr: IntegrationsManagerService,
    public auth: AuthenticationService,
    public jobsMgr: JobsManagerService
  ) {}

  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  openAssignJobModal(): void {
    this.integrationsMgr.isVisibleAssignJob = true;
  }
  handleAssignJobCancel(): void {
    this.integrationsMgr.isVisibleAssignJob = false;
  }
  toggleDropdown(key: string) {
    for (const k in this.isDropdownOpen) {
      if (k !== key) {
        this.isDropdownOpen[k] = false;
      }
    }

    this.isDropdownOpen[key] = true;
  }

  changeJobProcessingSettings(item: any): void {
    const formData: any = {
      auto_job_processing: item,
    };
    this.integrationsMgr.updateJobProcessingSettings(formData);
  }
  isJobGranted(user: any, jobId: string): boolean {
    return user.access_status_jobs.some(
      (job: any) => job.job_obj_id === jobId && job.status === 'granted'
    );
  }
  isJobRequested(user: any, jobId: string): boolean {
    return user.access_status_jobs.some(
      (job: any) => job.job_obj_id === jobId && job.status === 'requested'
    );
  }
  isJobRequestedByUser(user: any): boolean {
    return user.access_status_jobs.some(
      (job: any) => job.status === 'requested'
    );
  }
  hasGrantedJobs(user: any): boolean {
    return user.access_status_jobs.some((job: any) => job.status === 'granted');
  }
  toggleAllJobs(user: any, isChecked: boolean): void {
    console.log('toggleAllJobs called for', user.user_id);

    user.access_status_jobs = user.assigned_jobs.map((job: any) => ({
      job_obj_id: job.job_obj_id,
      status: isChecked ? 'granted' : 'requested',
    }));
  }

  toggleJobSelection(user: any, jobId: string, isChecked: boolean): void {
    console.log(
      'toggleJobSelection called for jobId:',
      jobId,
      'isChecked:',
      isChecked
    );

    const existingJob = user.access_status_jobs.find(
      (job: any) => job.job_obj_id === jobId
    );

    if (existingJob) {
      // Job exists, update its status based on isChecked
      existingJob.status = isChecked ? 'granted' : 'reverted';
      console.log('Updated existing job:', existingJob);
    } else if (isChecked) {
      // If checked and the job doesn't exist, you might want to add it
      user.access_status_jobs.push({
        job_obj_id: jobId,
        status: 'granted',
      });
      console.log('Added new job to access_status_jobs:', {
        job_obj_id: jobId,
        status: 'granted',
      });
    } else {
      console.log('Job not found and not checked, no action taken.');
    }

    console.log('Current access_status_jobs:', user.access_status_jobs);
  }

  isAllJobsSelected(user: any): boolean {
    if (!user.assigned_jobs || user.assigned_jobs.length === 0) {
      return false;
    }

    return user.assigned_jobs.every((assignedJob: any) =>
      user.access_status_jobs.some(
        (accessJob: any) =>
          accessJob.job_obj_id === assignedJob.job_obj_id &&
          accessJob.status === 'granted'
      )
    );
  }

  onCloseAllJobs(user: any): void {
    user.access_status_jobs.forEach((job: any) =>
      job.status === 'requested' ? 'requested' : 'revoked'
    );
  }

  onCloseJob(user: any, jobId: string): void {
    const jobToUpdate = user.access_status_jobs.find(
      (job: any) => job.job_obj_id === jobId
    );

    if (jobToUpdate) {
      jobToUpdate.status =
        jobToUpdate.status === 'requested' ? 'requested' : 'revoked';
    }
  }

  isJobAssigned(user: any, jobId: string): boolean {
    return user.access_status_jobs.some(
      (job: any) => job.job_obj_id === jobId && job.status === 'granted'
    );
  }

  getGrantedJobs(user: any): any[] {
    return user.access_status_jobs.filter(
      (job: any) => job.status === 'granted'
    );
  }
  isAllJobsAssigned(user: any): boolean {
    return (
      user.assigned_jobs.length > 0 &&
      user.assigned_jobs.length === this.getGrantedJobs(user).length
    );
  }

  getJobNameById(user: any, jobId: string): string {
    const job = user.assigned_jobs.find((job: any) => job.job_obj_id === jobId);
    return job ? job.job_title : 'Unknown Job';
  }

  submitForm(): void {
    const payload = {
      users: this.integrationsMgr.jobRefinementUsers.map((user: any) => ({
        user_id: user.user_id,
        access_status_jobs: user.access_status_jobs.map((job: any) => ({
          job_obj_id: job.job_obj_id,
          status: job.status,
        })),
      })),
    };
    this.integrationsMgr.updateJobRefinementUsers(payload);
  }

  onTabChange(index: number): void {
    if (index === 1) {
      this.integrationsMgr.getJobRefinementUsers();
    }
  }
  onSettingsChange() {
    const followupSettings = this.integrationsMgr.followupSettings;
    if (followupSettings.is_enabled === true) {
      // If parent is on, ensure all child switches are on if they were off
      followupSettings.additional_insights.learning_agility = true;
      followupSettings.additional_insights.critical_thinking = true;
      followupSettings.additional_insights.distance_traveled = true;
      followupSettings.general = true;
    } else if (followupSettings.is_enabled === false) {
      // If parent is off, ensure all child switches are off
      followupSettings.additional_insights.learning_agility = false;
      followupSettings.additional_insights.critical_thinking = false;
      followupSettings.additional_insights.distance_traveled = false;
      followupSettings.general = false;
    }

    // Save settings whenever any switch (parent or child) is changed
    this.integrationsMgr.updateFollowupQuestionsSettings(followupSettings);
  }
  onChildSwitchChange() {
    this.integrationsMgr.updateFollowupQuestionsSettings(
      this.integrationsMgr.followupSettings
    );
  }

  ngOnInit(): void {
    this.integrationsMgr.getJobProcessingSettings();
    this.integrationsMgr.followupQuestionsSettings();
    this.jobsMgr.getPartnerJobFlow();
  }
  ngOnDestroy(): void {
    this.jobsMgr.selectedIndex = 0;
  }
  // Listener for clicks on the document
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    for (const key in this.isDropdownOpen) {
      if (!target.closest('.custom-select')) {
        this.isDropdownOpen[key] = false;
      }
    }
  }
}
