import { Component, ViewChild } from '@angular/core';
import { DashboardManagerService } from 'src/app/Managers/dashboard-manager.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexNoData,
  ApexAnnotations,
  ApexStroke,
  ApexTooltip,
  ApexMarkers,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  noData: ApexNoData;
  annotations: ApexAnnotations;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  markers: ApexMarkers;
};

@Component({
  selector: 'app-dein-home',
  templateUrl: './dein-home.component.html',
  styleUrls: ['./dein-home.component.scss'],
})
export class DeinHomeComponent {
  isChartVisible = false;
  isChartAssigneVisible = false;
  options = ['Weekly', 'Month', 'All Time'];
  selectedTimeframe = 'Weekly';
  selectedChartType = 'Applications';
  partnerId: number = 0;
  daysOfWeek = ['S', 'M', 'T', 'W', 'TH', 'F', 'S'];

  @ViewChild('chartCandidates', { static: true })
  chartCandidates!: ChartComponent;
  @ViewChild('chartAssigne', { static: true }) chartAssigne!: ChartComponent;
  @ViewChild('chartAssigneJobs', { static: true })
  chartAssigneJobs!: ChartComponent;
  @ViewChild('chartAssigneJobsLifecycle', { static: true })
  chartAssigneJobsLifecycle!: ChartComponent;

  public chartOptionsCandidates!: Partial<ChartOptions>;
  public chartOptionsAssigne!: Partial<ChartOptions>;
  public chartOptionsAssigneJob!: Partial<ChartOptions>;
  public chartOptionsAssigneJobLifecycle!: Partial<ChartOptions>;

  currentPage = 0;
  recordsPerPage = 3;
  totalPages: number = 0;

  searchValue = '';
  visible = false;

  constructor(public dashboardMgr: DashboardManagerService) {}
  private getCategory(timeframe: string) {
    if (timeframe === 'Weekly') {
      return this.dashboardMgr.applications.analytics.map((item: any) => {
        const dayIndex = new Date(item.date).getDay();
        return this.daysOfWeek[dayIndex];
      });
    } else if (timeframe === 'Monthly') {
      return this.dashboardMgr.applications.analytics.map(
        (item: any) => item.day
      );
    } else {
      return this.dashboardMgr.applications.analytics.map(
        (item: any) => item.date
      );
    }
  }
  private determineChartType(timeframe: any): any {
    return timeframe === 'All time' ? 'area' : 'bar';
  }
  private drawChartCandidates(timeframe: string) {
    const categories = this.getCategory(timeframe);
    const chartType = this.determineChartType(timeframe);
    this.isChartVisible = true;
    this.chartOptionsCandidates = {
      series: [
        {
          name: 'Previous Candidates',
          data: this.dashboardMgr.applications.analytics.map(
            (item) => item.count
          ),
        },
        {
          name: 'New Candidates',
          data: this.dashboardMgr.applications.analytics.map(
            (item) => item.count - item.last_count
          ),
        },
      ],
      noData: {
        text: 'No data to display',
        align: 'center',
      },
      chart: {
        type: chartType,
        height: 250,
        stacked: timeframe === 'All time' ? false : true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
        colors: ['#5822d3', '#b09ee5'],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%',
          borderRadius: 12,
        },
      },
      xaxis: {
        type: 'category',
        categories: categories,
        position: 'top',

        labels: {
          show: timeframe === 'All time' ? false : true,
        },
        tooltip: {
          enabled: false, // Disable the x-axis tooltip
        },
      },

      legend: {
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#5822d3', '#b09ee5'],
          width: 8,
          height: 8,
          radius: 100,
        },
      },
      tooltip: {
        marker: {
          fillColors: ['#5822d3', '#b09ee5'],
        },
      },
      markers: {
        colors: ['#5822d3', '#b09ee5'],
      },
      fill: {
        opacity: 1,
        colors: ['#5822d3', '#b09ee5'],
      },
    };
  }
  private drawChart(page: number) {
    const startIndex = page * this.recordsPerPage;
    const endIndex = Math.min(
      startIndex + this.recordsPerPage,
      this.dashboardMgr.assinee.length
    );
    this.totalPages = Math.ceil(
      this.dashboardMgr.assinee.length / this.recordsPerPage
    );

    this.chartOptionsAssigne = {
      series: [
        {
          name: 'Qualified',
          data: this.dashboardMgr.assinee
            .slice(startIndex, endIndex)
            .map((item) => item.qualified),
        },
        {
          name: 'Not Qualified',
          data: this.dashboardMgr.assinee
            .slice(startIndex, endIndex)
            .map((item) => item.not_qualified),
        },
      ],
      noData: {
        text: 'No data to display',
        align: 'center',
      },
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%',
          borderRadius: 12,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.dashboardMgr.assinee
          .slice(startIndex, endIndex)
          .map((item) => item.user_name),
        position: 'top',
        labels: {
          rotate: 0,
          trim: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#5822d3', '#b09ee5'],
          width: 8,
          height: 8,
          radius: 100,
        },
      },
      fill: {
        opacity: 1,
        colors: ['#5822d3', '#b09ee5'],
      },
    };

    this.chartOptionsAssigneJob = {
      series: [
        {
          name: 'Open jobs',
          data: this.dashboardMgr.assigneeJobs
            .slice(startIndex, endIndex)
            .map((item) => item.open_jobs),
        },
        {
          name: 'Closed Jobs',
          data: this.dashboardMgr.assigneeJobs
            .slice(startIndex, endIndex)
            .map((item) => item.closed_jobs),
        },
      ],
      noData: {
        text: 'No data to display',
        align: 'center',
      },
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%',
          borderRadius: 12,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.dashboardMgr.assigneeJobs
          .slice(startIndex, endIndex)
          .map((item) => item.user_name),
        position: 'top',
        labels: {
          rotate: 0,
          trim: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#5822d3', '#b09ee5'],
          width: 8,
          height: 8,
          radius: 100,
        },
      },
      fill: {
        opacity: 1,
        colors: ['#5822d3', '#b09ee5'],
      },
    };
    this.chartOptionsAssigneJobLifecycle = {
      series: [
        {
          name: 'Avg. Days Open',
          data: this.dashboardMgr.assigneeJobsLifecycle
            .slice(startIndex, endIndex)
            .map((item) => item.avg_job_life),
        },
      ],
      noData: {
        text: 'No data to display',
        align: 'center',
      },
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%',
          borderRadius: 12,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.dashboardMgr.assigneeJobsLifecycle
          .slice(startIndex, endIndex)
          .map((item) => item.user_name),
        position: 'top',
        labels: {
          rotate: 0,
          trim: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#5822d3', '#b09ee5'],
          width: 8,
          height: 8,
          radius: 100,
        },
      },
      fill: {
        opacity: 1,
        colors: ['#5822d3', '#b09ee5'],
      },
    };
    this.isChartAssigneVisible = true;
    setTimeout(() => {
      console.clear();
    }, 2000);
  }
  async getAnaylytics(timeframe: string, label: string) {
    this.selectedTimeframe = label;
    await this.dashboardMgr.getAnalytics(
      timeframe,
      this.dashboardMgr.partners[0].partner_id
    );
    this.drawChartCandidates(this.selectedTimeframe);
  }
  changeChartType(type: string) {
    this.selectedChartType = type;
    this.drawChart(this.currentPage);
    this.drawChartCandidates(this.selectedTimeframe);
  }
  // async onTabChange(args: any): Promise<void> {
  //   const title = args.tab.nzTitle;

  //   const partner = this.dashboardMgr.partners.find(
  //     (partner) => partner.partner_name === title
  //   );
  //   this.partnerId = partner?.partner_id || 0;
  //   if (partner) {
  //     await this.dashboardMgr.getAnalytics('weekly', this.partnerId);
  //     this.drawChart(this.currentPage);
  //     this.drawChartCandidates(this.selectedTimeframe);
  //   }
  // }
  onAssingeTabChange(): void {
    this.drawChart(this.currentPage);
    this.drawChartCandidates(this.selectedTimeframe);
  }
  async handleTimeSavedChange(e: number): Promise<void> {
    let indexTimframe: 'weekly' | 'monthly' | 'all_time' = 'weekly';

    if (e === 1) {
      indexTimframe = 'monthly';
    } else if (e === 2) {
      indexTimframe = 'all_time';
    }

    await this.dashboardMgr.getAnalytics(indexTimframe, this.partnerId);
  }
  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.drawChart(this.currentPage);
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.drawChart(this.currentPage);
    }
  }
  handlePageIndexChange(pageIndex: number): void {
    this.dashboardMgr.partnerPage = pageIndex;
    this.dashboardMgr.getPartners(
      this.dashboardMgr.partnerPage,
      this.dashboardMgr.partner_name
    );
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    console.log(this.searchValue);
    this.visible = false;
    this.dashboardMgr.getPartners(
      this.dashboardMgr.partnerPage,
      this.searchValue
    );
  }
  async selectRow(partner_id: number) {
    this.partnerId = partner_id;
    await this.dashboardMgr.getAnalytics('weekly', this.partnerId);
    this.drawChart(this.currentPage);
    this.drawChartCandidates(this.selectedTimeframe);
  }
  async ngOnInit() {
    await this.dashboardMgr.getPartners(1, '');
    const defaultPartnerId = this.dashboardMgr.partners[0].partner_id;
    this.partnerId = defaultPartnerId;
    await this.dashboardMgr.getAnalytics('weekly', defaultPartnerId);
    this.drawChart(this.currentPage);
    this.drawChartCandidates(this.selectedTimeframe);
  }
  ngOnDestroy() {
    this.searchValue = '';
    this.dashboardMgr.partnerPage = 1;
  }
}
