import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrl: './apply-job.component.scss',
})
export class ApplyJobComponent {
  jobObjId: string = '';
  token: string = '';
  buttonJobApplication: boolean = true;
  buttonAbout: boolean = false;
  isVisibleAboutModal: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public sjmMgr: SjmManagerService,
    private sanitizer: DomSanitizer
  ) {}
  openAboutModal(): void {
    this.isVisibleAboutModal = true;
    this.buttonJobApplication = false;
    this.buttonAbout = true;
  }
  handleAboutCancel(): void {
    this.isVisibleAboutModal = false;
    this.buttonJobApplication = true;
    this.buttonAbout = false;
  }
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '<br />') // Replace newlines with <br>
      .replace(/\n/g, '<br />') // Replace literal newlines with <br>
      .replace(/\\f/g, '') // Remove form feed characters
      .replace(/\f/g, '') // Remove literal form feed
      .replace(/\\t/g, '&emsp;') // Replace tab with spaces
      .replace(/\t/g, '&emsp;') // Replace literal tab with spaces
      .replace(/\\u00a0/g, '&nbsp;') // Non-breaking space
      .replace(/\\u0026/g, '&amp;') // Ampersand
      .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>') // Bold for text between **
      .replace(/[^\x00-\x7F]/g, ''); // Remove non-ASCII characters
  }
  colorToLinks(html: string, color: string): SafeHtml {
    console.log(color);
    const modifiedHtml = html.replace(/<a /g, `<a style="color: ${color};" `);
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtml);
  }
  // Helper function to determine if the color is dark or light
  isColorDark(color: string): boolean {
    // Check if color is in hex format, remove '#' if it exists
    let hex = color.startsWith('#') ? color.slice(1) : color;

    // Pad with zeros if it's shorthand hex (e.g., #abc to #aabbcc)
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Convert to RGB values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Calculate brightness using luminance formula
    const brightness = r * 0.299 + g * 0.587 + b * 0.114;

    // Return true if the color is dark
    return brightness < 150;
  }

  // Method to get styles for the button
  getButtonStyles() {
    const backgroundColor =
      this.sjmMgr.companyBasicData.appearance.primary_containers || '#f2f2f8';
    const isDark = this.isColorDark(backgroundColor);

    return {
      backgroundColor: backgroundColor,
      color: isDark ? '#ffffff' : '#000000',
    };
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.jobObjId = params['job_obj_id'];
      this.token = params['token'];
      await this.sjmMgr.getJobInformation(this.jobObjId);
    });
  }
}
