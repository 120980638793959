import {
  Component,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import {
  CdkDragEnter,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Subscription, interval } from 'rxjs';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { JobAISettingsManagerService } from 'src/app/Managers/job-aisettings-manager.service';
import { ScreeningSettingsManagerService } from 'src/app/Managers/screening-settings-manager.service';
import { JobAISettingComponent } from './job-aisetting/job-aisetting.component';
import { AssignJobComponent } from 'src/app/components/partials/assign-job/assign-job.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SortingCriteriaComponent } from './sorting-criteria/sorting-criteria.component';
import { AssignJobManagerService } from 'src/app/Managers/assign-job-manager.service';
import { WebSocketService } from 'src/app/Services/socket/web-socket.service';
import { CandidatesManagerService } from 'src/app/Managers/candidates-manager.service';
import { CommentsManagerService } from 'src/app/Managers/comments-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';

import { candidates } from 'src/app/models/users';

export interface UpdateCardOrderPayload {
  application_id: number;
  index: number;
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss',
})
export class ApplicationsComponent {
  user: any;
  private subscriptions: Subscription = new Subscription();
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  @ViewChild('scrollTarget') scrollTarget!: ElementRef;
  segmentList: any[] = [];
  applicationIds: any[] = [];
  advancedStepId: string = '';
  public jobStatus: string = '';
  public ats: string = '';
  public processStateDetail: string = '';
  showUpload: boolean = false;
  showProgressBar: boolean = true;
  received_application_count: number = 0;
  processed_application_count: number = 0;
  previousProcessedApplicationCount: number = 0;
  job_apply_link: string = '';
  processingPercentage: number = 0;
  minScore: number = 0;
  maxScore: number = 100;
  isOpenAdvaceFilter = false;
  isOpenQuestionsSubMenu = false;
  isOpenLocationSubMenu = false;
  isOpenRequirmentsSubMenu = false;
  isExtractionCompleted = false;

  @ViewChild(CandidateProfileComponent)
  profileModal!: CandidateProfileComponent;

  @ViewChild(JobAISettingComponent)
  aiSettingModal!: JobAISettingComponent;

  @ViewChild(SortingCriteriaComponent)
  sortingCriteriaModal!: SortingCriteriaComponent;

  @ViewChild(AssignJobComponent)
  assignJobSettingModal!: AssignJobComponent;
  searchForm!: UntypedFormGroup;
  shareHiringForm!: UntypedFormGroup;
  subscription!: Subscription;
  allowDropOnFirstHalf: boolean = false;
  isVisibleAISettings: boolean = false;
  isBookmarkedActive: boolean = false;
  isActiveFollowUpQuestionsTab: boolean = false;
  isActiveProfile: boolean = false;
  adjustAIWeights: boolean = false;
  isCollapsed: { [key: string]: boolean } = {
    not_qualified: true,
  };
  jobObjectId: string = '';
  atsName: string = '';
  candidateId: string = '';
  isProcessing: boolean = false;
  application_obj_id = '';
  applicationId = 0;
  scoreOptions = Array.from({ length: 21 }, (_, i) => i * 5);
  isChecked: boolean[] = [];
  isIndeterminate: boolean[] = [];
  setOfCheckedId = new Set<string>();
  //Filters
  isShareFilter: boolean = false;
  isThumbsUpFilter: boolean = false;
  isThumbsDownFilter: boolean = false;
  isAdvancedFilter: boolean = false;
  isRejectFilter: boolean = false;
  isSearchValue: string = '';
  isAnsweredFollowupQuestions: boolean = false;
  yes_no_filters: string = '';
  scale_filters: string = '';
  private yesNoState: { [id: string]: string[] } = {};
  private scaleState: { [id: string]: number[] } = {};
  isProfile: boolean = false;
  thumbTitle: string = '';
  isThumbUp: boolean = false;
  locationsFormated: string = '';
  job_req_id: string = '';
  job_req_name: string = '';
  sortOrder: { [key: string]: 'asc' | 'desc' } = {
    not_qualified: 'asc',
    pre_qualified: 'asc',
    qualified: 'asc',
  };
  selectedLocations: string[] = [];
  locationChecked: { [key: string]: boolean } = {};
  selectedRequirments: any[] = [];
  requirmentsChecked: { [key: string]: boolean } = {};
  questionsValues: Record<string, any> = {};
  scaleOptions = [1, 2, 3, 4, 5];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public applicationMgr: ApplicationManagerService,
    public screeningMgr: ScreeningSettingsManagerService,
    public aiManager: JobAISettingsManagerService,
    private fb: UntypedFormBuilder,
    public assignJobMgr: AssignJobManagerService,
    private webSocketService: WebSocketService,
    private changeDetectorRef: ChangeDetectorRef,
    public candidateMgr: CandidatesManagerService,
    public commentMgr: CommentsManagerService,
    public auth: AuthenticationService,
    public jobsMgr: JobsManagerService,
    public followUpMgr: FollowUpQuestionsManagerService
  ) {}
  onSettingsChange() {
    let data = {
      is_enabled: this.applicationMgr.jobDetailData.generate_followup_questions,
    };
    this.applicationMgr.jobDetailFollowUpSettings(this.jobObjectId, data);
  }
  handleCancelModal(): void {
    this.applicationMgr.isConfirmStageChangeModal = false;
  }
  changeJobStage(job_status_name: string) {
    this.applicationMgr.jobStatus = job_status_name;
    this.applicationMgr.isConfirmStageChangeModal = true;
  }
  confirmStageChange() {
    let data = {
      status: this.applicationMgr.jobStatus,
    };
    this.applicationMgr.changeJobStage(
      this.applicationMgr.jobStatus,
      this.jobObjectId,
      data
    );
  }
  toggleAdvacedFilterDropdown() {
    this.isOpenAdvaceFilter = !this.isOpenAdvaceFilter;
  }
  toggleSubMenu(subMenu: string) {
    if (subMenu === 'questions') {
      this.isOpenQuestionsSubMenu = !this.isOpenQuestionsSubMenu;
      this.isOpenLocationSubMenu = false;
      this.isOpenRequirmentsSubMenu = false;
      this.initializeCheckboxValues();
    } else if (subMenu === 'location') {
      this.isOpenLocationSubMenu = !this.isOpenLocationSubMenu;
      this.isOpenQuestionsSubMenu = false;
      this.isOpenRequirmentsSubMenu = false;
      this.applicationMgr.candidateLocations(this.jobObjectId);
    } else if (subMenu === 'requirements') {
      this.isOpenRequirmentsSubMenu = !this.isOpenRequirmentsSubMenu;
      this.isOpenQuestionsSubMenu = false;
      this.isOpenLocationSubMenu = false;
      this.applicationMgr.candidateLocations(this.jobObjectId);
    }
  }
  initializeCheckboxValues(): void {
    const hasExistingFilters = Object.keys(this.questionsValues).length > 0;
    if (!hasExistingFilters && this.followUpMgr.questionsOnJob.length > 0) {
      this.followUpMgr.questionsOnJob.forEach((question) => {
        if (question.type === 'yes_no') {
          this.questionsValues[question._id] = { yes: false, no: false };
        } else if (question.type === 'scale') {
          this.questionsValues[question._id] = {};
          this.scaleOptions.forEach((scale) => {
            this.questionsValues[question._id][scale] = false;
          });
        }
      });
    }
  }

  onChangeQuestionFilter(
    id: string,
    value: string | number,
    checked: boolean,
    type: string
  ): void {
    if (type === 'yes_no') {
      if (!this.yesNoState[id]) {
        this.yesNoState[id] = [];
      }
      if (checked) {
        if (!this.yesNoState[id].includes(value as string)) {
          this.yesNoState[id].push(value as string);
        }
      } else {
        this.yesNoState[id] = this.yesNoState[id].filter((v) => v !== value);
        if (this.yesNoState[id].length === 0) {
          delete this.yesNoState[id];
        }
      }
      this.yes_no_filters = this.formatFilters(this.yesNoState);
    } else if (type === 'scale') {
      if (!this.scaleState[id]) {
        this.scaleState[id] = [];
      }
      if (checked) {
        if (!this.scaleState[id].includes(value as number)) {
          this.scaleState[id].push(value as number);
        }
      } else {
        this.scaleState[id] = this.scaleState[id].filter((v) => v !== value);
        if (this.scaleState[id].length === 0) {
          delete this.scaleState[id];
        }
      }
      this.scale_filters = this.formatFilters(this.scaleState);
    }
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }

  private formatFilters(state: { [id: string]: (string | number)[] }): string {
    return Object.keys(state)
      .map((key) => `${key}:${state[key].join(',')}`)
      .join(';');
  }
  isFilterApplied(id: string): boolean {
    return Object.values(this.questionsValues[id] || {}).some((value) => value);
  }
  onClosequestionsValues(id: string): void {
    if (this.yesNoState[id]) {
      delete this.yesNoState[id];
      this.yes_no_filters = this.formatFilters(this.yesNoState);
      this.questionsValues[id] = { yes: false, no: false };
    }

    if (this.scaleState[id]) {
      delete this.scaleState[id];
      this.scale_filters = this.formatFilters(this.scaleState);
      this.scaleOptions.forEach((scale) => {
        this.questionsValues[id][scale] = false;
      });
    }

    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  clearAdvancedFilter() {
    this.isAnsweredFollowupQuestions = false;
    this.locationsFormated = '';
    this.selectedLocations = [];
    this.locationChecked = {};
    this.requirmentsChecked = {};
    this.selectedRequirments = [];
    this.yes_no_filters = '';
    this.scale_filters = '';
    this.questionsValues = {};
    this.job_req_id = '';
    this.job_req_name = '';
    this.initializeCheckboxValues();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.custom-dropdown')) {
      this.isOpenAdvaceFilter = false;
    }
  }

  closeSubMenu(subMenu: string) {
    this.isOpenQuestionsSubMenu = false;
    this.isOpenLocationSubMenu = false;
    this.isOpenRequirmentsSubMenu = false;
  }
  isStageDisabled(stageName: string, currentStageName: any): boolean {
    const stages = this.applicationMgr.screeningStepsOptions.map(
      (stage) => stage.job_stage_name
    );
    const currentStageIndex = stages.indexOf(currentStageName);
    const stageIndex = stages.indexOf(stageName);
    return stageIndex < currentStageIndex;
  }
  onChangeFollowupQuestions(checked: boolean) {
    this.isAnsweredFollowupQuestions = checked;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onCloseFollowupQuestions() {
    this.isAnsweredFollowupQuestions = false;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onChangeLocation(isChecked: boolean, location: string): void {
    if (isChecked) {
      this.selectedLocations.push(location);
    } else {
      this.selectedLocations = this.selectedLocations.filter(
        (loc) => loc !== location
      );
    }
    const selectedLocationsString = this.selectedLocations.join('","');
    this.locationsFormated = `"${selectedLocationsString}"`;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onChangeRequirements(isChecked: boolean, requirment: string): void {
    if (isChecked) {
      this.selectedRequirments.push(requirment);
    } else {
      this.selectedRequirments = this.selectedRequirments.filter(
        (loc) => loc !== requirment
      );
    }
    const idList = this.selectedRequirments.map((item) => item.id).join(',');
    const requirmentName = this.selectedRequirments
      .map((item) => item.requirmentName)
      .join(',');
    this.job_req_id = idList;
    this.job_req_name = requirmentName;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onCloseRequirements() {
    this.requirmentsChecked = {};
    this.selectedRequirments = [];
    this.job_req_id = '';
    this.job_req_name = '';
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onCloseLocations() {
    this.locationsFormated = '';
    this.selectedLocations = [];
    this.locationChecked = {};
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  toggleShareFilter() {
    this.isShareFilter = !this.isShareFilter;
    const candidates = this.applicationMgr.getCandidateBytype(status);
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  toggleThumbsUpFilter() {
    this.isThumbsUpFilter = !this.isThumbsUpFilter;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  toggleThumbsDownFilter() {
    this.isThumbsDownFilter = !this.isThumbsDownFilter;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  toggleAdvancedFilter() {
    this.isAdvancedFilter = !this.isAdvancedFilter;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  toggleRejectFilter() {
    this.isRejectFilter = !this.isRejectFilter;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  onSearchIntials(): void {
    this.submitSearch();
  }
  submitSearch(): void {
    const searchValue = this.searchForm.value.search;
    this.isSearchValue = searchValue;
    if (searchValue === null || searchValue === '') {
      this.getApplicationsCalls(
        '',
        this.minScore,
        this.maxScore,
        false,
        this.isAdvancedFilter,
        this.isRejectFilter,
        this.isShareFilter,
        this.isThumbsDownFilter,
        this.isThumbsUpFilter,
        this.isAnsweredFollowupQuestions,
        this.locationsFormated,
        this.job_req_id,
        this.job_req_name,
        this.yes_no_filters,
        this.scale_filters
      );
    } else {
      this.getApplicationsCalls(
        this.isSearchValue,
        this.minScore,
        this.maxScore,
        false,
        this.isAdvancedFilter,
        this.isRejectFilter,
        this.isShareFilter,
        this.isThumbsDownFilter,
        this.isThumbsUpFilter,
        this.isAnsweredFollowupQuestions,
        this.locationsFormated,
        this.job_req_id,
        this.job_req_name,
        this.yes_no_filters,
        this.scale_filters
      );
    }
  }

  onChangeRange(value: any, index: number): void {
    if (index === 0) {
      this.applicationMgr.range[0] = value;
      this.minScore = value;
    } else if (index === 1) {
      this.applicationMgr.range[1] = value;
      this.maxScore = value;
    }

    this.getApplicationsCalls(
      this.isSearchValue,
      this.applicationMgr.range[0],
      this.applicationMgr.range[1],
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  // application checkboxes start
  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(status: string): void {
    const candidates = this.applicationMgr.getCandidateBytype(status);
    const checkedCandidates = candidates.filter((candidate) =>
      this.setOfCheckedId.has(candidate._id)
    );
    const allChecked = checkedCandidates.length === candidates.length;
    const someChecked = checkedCandidates.length > 0 && !allChecked;

    this.setIndeterminateState(status, someChecked);
    this.setCheckedState(status, allChecked);
  }

  setCheckedState(status: string, checked: boolean): void {
    const index = this.segmentList.indexOf(status);
    this.isChecked[index] = checked;
  }

  setIndeterminateState(status: string, indeterminate: boolean): void {
    const index = this.segmentList.indexOf(status);
    this.isIndeterminate[index] = indeterminate;
  }

  onItemChecked(status: string, id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus(status);
  }

  onAllChecked(status: string, checked: boolean): void {
    const candidates = this.applicationMgr.getCandidateBytype(status);
    candidates.forEach(({ _id }) => this.updateCheckedSet(_id, checked));
    this.refreshCheckedStatus(status);
  }
  // application checkboxes end

  async submitShareApplications(): Promise<void> {
    let data = {
      application_ids: this.applicationIds,
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'share',
    };
    await this.applicationMgr.shareApplications(data);
    this.shareHiringForm.reset();

    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }

  async submitAdvancedApplications(): Promise<void> {
    let data = {
      application_ids: this.applicationIds,
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'advance',
      advanced_step_id: this.advancedStepId,
    };
    await this.applicationMgr.submitAdvancedApplications(data);
    this.shareHiringForm.reset();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  async submitRejectApplications(): Promise<void> {
    let data = {
      application_ids: this.applicationIds,
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'reject',
    };
    await this.applicationMgr.submitRejectApplications(data);
    this.shareHiringForm.reset();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  async submitShareBulkApplications(): Promise<void> {
    let data = {
      application_ids: [...this.setOfCheckedId],
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'share',
    };
    await this.applicationMgr.shareApplications(data);
    this.shareHiringForm.reset();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  async submitAdvancedBulkApplicationsModal() {
    this.advancedStepId = '';
    this.applicationIds = [...this.setOfCheckedId];
    this.applicationMgr.isVisibleAdvancedBulkModal = true;
  }
  async submitRejectBulkApplicationsModal() {
    this.advancedStepId = '';
    this.applicationIds = [...this.setOfCheckedId];
    this.applicationMgr.isVisibleRejectBulkModal = true;
  }
  onStepChange(selectedValue: any): void {
    this.advancedStepId = selectedValue;
  }
  async submitAdvancedBulkApplications(): Promise<void> {
    let data = {
      application_ids: [...this.setOfCheckedId],
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'advance',
      advanced_step_id: this.advancedStepId,
    };
    await this.applicationMgr.submitAdvancedApplications(data);
    this.shareHiringForm.reset();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  async submitRejectBulkApplications(): Promise<void> {
    let data = {
      application_ids: [...this.setOfCheckedId],
      user_role: 'Hiring Manager',
      note: this.shareHiringForm.value.note,
      action_type: 'reject',
    };
    await this.applicationMgr.submitRejectApplications(data);
    this.shareHiringForm.reset();
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }
  async discardChanges(action_type: number): Promise<void> {
    let formData: any = {};
    formData['action_type'] = action_type;
    this.aiManager.aisSettingsActive.forEach(
      (setting: { key: string; value: any }) => {
        formData[setting.key] = setting.value;
      }
    );
    await this.aiManager.updateAiSettings(
      formData,
      this.jobObjectId,
      action_type
    );
    this.aiSettingModal.aiPayloadReload();
    this.adjustAIWeights = false;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }

  adjustChanges(): void {
    this.aiSettingModal.isVisible = true;
  }
  async saveChanges(action_type: number): Promise<void> {
    const data = {
      ...this.aiManager.weightPayload,
      action_type: action_type,
    };

    await this.aiManager.updateAiSettings(data, this.jobObjectId, action_type);
    this.aiSettingModal.aiPayloadReload();
    this.adjustAIWeights = false;
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
  }

  async onChildSubmitSorting(): Promise<void> {
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      false,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
    this.aiSettingModal.isVisible = false;
    this.sortingCriteriaModal.isVisible = false;
  }
  async onChildSubmitAiSettings(): Promise<void> {
    this.getApplicationsCalls(
      this.isSearchValue,
      this.minScore,
      this.maxScore,
      true,
      this.isAdvancedFilter,
      this.isRejectFilter,
      this.isShareFilter,
      this.isThumbsDownFilter,
      this.isThumbsUpFilter,
      this.isAnsweredFollowupQuestions,
      this.locationsFormated,
      this.job_req_id,
      this.job_req_name,
      this.yes_no_filters,
      this.scale_filters
    );
    this.adjustAIWeights = true;
    this.aiSettingModal.isVisible = false;
    this.sortingCriteriaModal.isVisible = false;
  }
  onChildSubmitAssignJob(): void {
    this.assignJobSettingModal.isVisible = false;
  }
  async getApplicationsCalls(
    search: string,
    min_score: number,
    max_score: number,
    is_adjust_ai_settings: boolean,
    advance: boolean,
    reject: boolean,
    share: boolean,
    thumbs_down: boolean,
    thumbs_up: boolean,
    answered_followup_questions: boolean,
    locations: string,
    job_req_id: string,
    job_req_name: string,
    yes_no_filters: string,
    scale_filters: string
  ): Promise<void> {
    this.showUpload = false;

    // Fetch applications for each qualification level with explicit calls
    await Promise.all([
      this.applicationMgr.getNotQualified(
        this.jobObjectId,
        'not_qualified',
        search,
        min_score,
        max_score,
        is_adjust_ai_settings,
        advance,
        reject,
        share,
        thumbs_down,
        thumbs_up,
        answered_followup_questions,
        locations,
        job_req_id,
        job_req_name,
        yes_no_filters,
        scale_filters
      ),
      this.applicationMgr.getPreQualified(
        this.jobObjectId,
        'pre_qualified',
        search,
        min_score,
        max_score,
        is_adjust_ai_settings,
        advance,
        reject,
        share,
        thumbs_down,
        thumbs_up,
        answered_followup_questions,
        locations,
        job_req_id,
        job_req_name,
        yes_no_filters,
        scale_filters
      ),
      this.applicationMgr.getQualified(
        this.jobObjectId,
        'qualified',
        search,
        min_score,
        max_score,
        is_adjust_ai_settings,
        advance,
        reject,
        share,
        thumbs_down,
        thumbs_up,
        answered_followup_questions,
        locations,
        job_req_id,
        job_req_name,
        yes_no_filters,
        scale_filters
      ),
    ]);

    // Helper function to load the application profile if available
    const loadProfileIfNeeded = async (profileList: any[]) => {
      if (profileList.length > 0) {
        this.isProfile = true;
        this.showUpload = true;
        try {
          await this.candidateMgr.getAppliationProfile(
            profileList[0]['_id'],
            this.adjustAIWeights
          );
        } catch (error) {
          console.error('Failed to load application profile:', error);
          this.isProfile = false;
          this.showUpload = false;
        }
      }
    };

    // Check each qualification level and load the profile accordingly
    if (
      this.applicationMgr.qualified.length === 0 &&
      this.applicationMgr.pre_qualified.length === 0 &&
      this.applicationMgr.not_qualified.length === 0
    ) {
      this.candidateMgr.applicationProfile = {} as candidates;
      this.isProfile = false;
      this.showUpload = true;
    } else if (this.applicationMgr.qualified.length > 0) {
      await loadProfileIfNeeded(this.applicationMgr.qualified);
    } else if (this.applicationMgr.pre_qualified.length > 0) {
      await loadProfileIfNeeded(this.applicationMgr.pre_qualified);
    } else if (this.applicationMgr.not_qualified.length > 0) {
      await loadProfileIfNeeded(this.applicationMgr.not_qualified);
    } else {
      this.showUpload = false;
      this.isProfile = false;
      this.candidateMgr.applicationProfile = {} as candidates;
    }

    // Ensure change detection for UI updates
    this.changeDetectorRef.detectChanges();
  }

  drop(event: any, segment: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    const segments: UpdateCardOrderPayload[] = event.container.data.map(
      (card: { _id: any }, index: any) => ({
        application_obj_id: card._id,
        segment: segment === 'all_applications' ? '' : segment,
        index: index,
      })
    );

    this.applicationMgr.updateSegment(segments);
  }

  onDragEntered(event: CdkDragEnter<any>, status: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = event.item.moved.subscribe((e) => {
      const dropTargetHeight =
        event.container.element.nativeElement.offsetHeight;
      const pointerPositionY =
        e.pointerPosition.y -
        event.container.element.nativeElement.getBoundingClientRect().top;
      this.allowDropOnFirstHalf = pointerPositionY <= dropTargetHeight / 2;
    });
  }

  getStatusTitle(status: string): string {
    switch (status) {
      case 'all_applications':
        return 'All';
      case 'not_qualified':
        return 'Not Qualified';
      case 'pre_qualified':
        return 'Pre-Qualified';
      case 'qualified':
        return 'Qualified';
      case 'reject':
        return 'Dismiss';
      case 'final_decision':
        return 'Advance';
      default:
        return '';
    }
  }

  getStatusPermissions(status: string): string {
    switch (status) {
      case 'all_applications':
        return 'all-applications';
      case 'not_qualified':
        return 'not-qualified';
      case 'pre_qualified':
        return 'pre-qualified';
      case 'qualified':
        return 'qualified';
      case 'reject':
        return 'reject';
      case 'final_decision':
        return 'final-decision';
      default:
        return '';
    }
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'not_qualified':
        return 'gray';
      default:
        return 'theme';
    }
  }
  getSegmentLength(data: any) {
    return data ? data.length : 0;
  }

  toggleCollapse(status: string): void {
    this.isCollapsed[status] = !this.isCollapsed[status];
  }
  getEmptyMessage(status: string): string {
    switch (status) {
      case 'all_applications':
        return 'No Applicants yet!';
      case 'not_qualified':
        return 'No Not-Qualified candidates!';
      case 'pre_qualified':
        return 'No pre-qualified candidates!';
      case 'qualified':
        return 'No qualified candidates!';
      case 'reject':
        return 'No Reject Candidates!';
      case 'final_decision':
        return 'No Final Decision Candidates!';
      default:
        return '';
    }
  }

  showProfileModal(id: string): void {
    this.application_obj_id = id;
    this.candidateMgr.getAppliationProfile(id, this.adjustAIWeights);
    this.commentMgr.getCommentsApplications(id);
    this.isProfile = true;
    if (this.scrollTarget) {
      this.scrollTarget.nativeElement.scrollTop = 0;
    }
  }
  shareModal(id: string): void {
    this.applicationIds = [id];
    this.applicationMgr.isVisibleShareModal = true;
  }
  removeShareCancel(): void {
    this.applicationMgr.isVisibleShareModal = false;
    this.shareHiringForm.reset();
  }
  advancedModal(applicationId: string, stepId: string): void {
    this.applicationIds = [applicationId];
    this.advancedStepId = stepId;
    if (this.ats === 'clara') {
      this.submitAdvancedApplications();
    } else {
      this.applicationMgr.isVisibleAdvancedModal = true;
    }
  }
  removeAdvancedCancel(): void {
    this.applicationMgr.isVisibleAdvancedModal = false;
  }
  removeAdvancedBulkCancel(): void {
    this.applicationMgr.isVisibleAdvancedBulkModal = false;
  }
  removeRejectBulkCancel(): void {
    this.applicationMgr.isVisibleRejectBulkModal = false;
  }
  rejectModal(id: string): void {
    this.applicationIds = [id];
    if (this.ats === 'clara') {
      this.submitRejectApplications();
    } else {
      this.applicationMgr.isVisibleRejectModal = true;
    }
  }
  removeRejectCancel(): void {
    this.applicationMgr.isVisibleRejectModal = false;
  }

  thumbsModal(id: string, thumbTitle: string, flag: boolean): void {
    this.application_obj_id = id;
    this.thumbTitle = thumbTitle;
    this.isThumbUp = flag;
    this.applicationMgr.isVisibleThumbsModal = true;
  }
  removeThumbsCancel(): void {
    this.applicationMgr.isVisibleThumbsModal = false;
    this.shareHiringForm.reset();
  }
  async submitThumbAction(): Promise<void> {
    let data = {
      thumb: this.isThumbUp,
    };
    await this.applicationMgr.submitThumbAction(
      data,
      this.application_obj_id,
      this.jobObjectId
    );
    this.shareHiringForm.reset();
    this.applicationMgr.hiringManagerSelection(this.jobObjectId);
  }

  async jobAISettingModal(): Promise<void> {
    this.aiSettingModal.isVisible = true;
    await this.aiManager.getAiSettings(this.jobObjectId);
    this.aiSettingModal.aiPayloadReload();
  }
  jobSortingCriteriaModal(): void {
    this.sortingCriteriaModal.isVisible = true;
    this.sortingCriteriaModal.sortingForm = true;
    this.sortingCriteriaModal.sortingConfirm = false;
    this.sortingCriteriaModal.isUpdatingPercentValue();
  }
  assignJobModal(): void {
    this.assignJobMgr.getAssignJobs(this.jobObjectId);
    this.assignJobSettingModal.isVisible = true;
  }
  gotoCandidatesUpload(jobObjectId: string): void {
    this.router.navigate(['/jobs/upload-candidates', jobObjectId]);
  }
  goToEditJobFlow(jobObjectId: string): void {
    this.router.navigate(['/jobs/job-flow', jobObjectId]);
  }

  sortSegment(status: string): void {
    const candidates = this.applicationMgr.getCandidateBytype(status);
    this.sortOrder[status] = this.sortOrder[status] === 'asc' ? 'desc' : 'asc';
    if (this.sortOrder[status] === 'desc') {
      candidates.sort(
        (a: { score: number }, b: { score: number }) => a.score - b.score
      );
    } else {
      candidates.sort(
        (a: { score: number }, b: { score: number }) => b.score - a.score
      );
    }
    this.applicationMgr.updateCandidates(status, candidates);
  }

  async ngOnInit() {
    this.applicationMgr.page = 1;
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.atsName = queryParams['atsName'];
      this.candidateId = queryParams['candidateId']
        ? queryParams['candidateId']
        : '';
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.isProcessing =
        queryParams['processing'] === 'processing' ? true : false;
    });

    this.searchForm = this.fb.group({
      search: [''],
    });
    this.shareHiringForm = this.fb.group({
      note: [''],
    });

    this.user = JSON.parse(localStorage.getItem('user') || '{}');

    await this.applicationMgr.jobDetail(this.jobObjectId, async () => {
      if (this.user.role !== 'Hiring Manager' && this.jobObjectId != '') {
        this.getApplicationsCalls(
          '',
          0,
          100,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          '',
          '',
          '',
          '',
          ''
        );
        this.applicationMgr.screeningStepsDropdown(this.jobObjectId);
      }
      if (this.user.role === 'Hiring Manager' && this.jobObjectId != '') {
        await this.applicationMgr.hiringManagerSelection(this.jobObjectId);
        this.showProfileModal(
          this.applicationMgr.hiringMangerSelections[0]['_id']
        );
        this.isProfile = true;
      }
      this.received_application_count = 0;
      this.processed_application_count = 0;

      this.processStateDetail =
        this.applicationMgr.jobDetailData['process_status'];
      this.received_application_count =
        this.applicationMgr.jobDetailData['received_application_count'];
      this.processed_application_count =
        this.applicationMgr.jobDetailData['processed_application_count'];
      this.ats = this.applicationMgr.jobDetailData['ats'];
      this.job_apply_link = this.applicationMgr.jobDetailData['job_apply_link'];
      if (this.jobObjectId != '') {
        this.startCheckingCounts();
      }

      this.processingPercentage = 0;
      this.processingPercentage = this.calculateProcessingPercentage(
        this.received_application_count,
        this.processed_application_count
      );
    });

    document.body.classList.add('bg-white');
    document.body.classList.add('desktop-view');
    this.applicationMgr.range = [0, 100];
    this.segmentList = ['qualified', 'pre_qualified', 'not_qualified'];
  }
  startCheckingCounts() {
    const checkInterval = 5000;

    this.subscription = interval(checkInterval).subscribe(async () => {
      if (!this.auth.isLoggedIn()) {
        this.subscription?.unsubscribe();
        return;
      }

      // Skip the interval entirely if counts are equal and extraction is completed.

      if (
        this.processed_application_count === this.received_application_count &&
        this.processStateDetail === 'EXTRACTION_COMPLETED'
      ) {
        this.subscription?.unsubscribe();
        console.log('No further action needed; interval stopped.');
        return;
      }

      // Fetch job application counts
      await this.applicationMgr.jobApplicationCounts(this.jobObjectId);

      // Get updated counts and process state after the call
      const {
        processed_application_count: updatedProcessed,
        received_application_count: updatedReceived,
        process_status: updatedProcessStatus,
      } = this.applicationMgr.currentCounts;

      this.processStateDetail = updatedProcessStatus;

      // Check and call jobDetail and getJobRequirementListing only once upon extraction completion
      if (
        this.processStateDetail === 'EXTRACTION_COMPLETED' &&
        !this.isExtractionCompleted
      ) {
        this.isExtractionCompleted = true;
        await this.applicationMgr.jobDetail(this.jobObjectId, async () => {});
        this.jobsMgr.getJobRequirmentListing(this.jobObjectId);
      }

      // Calculate processing percentage and control progress bar visibility
      if (updatedProcessed !== null && updatedReceived !== null) {
        this.processingPercentage = this.calculateProcessingPercentage(
          updatedReceived,
          updatedProcessed
        );
      }

      if (this.processingPercentage === 100) {
        setTimeout(() => {
          this.showProgressBar = false;
          this.changeDetectorRef.detectChanges();
        }, 3000);
      } else {
        this.showProgressBar = true;
      }

      // Call getApplicationsCalls if there is a new update in processed applications
      if (
        updatedProcessed &&
        updatedProcessed > this.previousProcessedApplicationCount
      ) {
        if (this.user.role !== 'Hiring Manager' && this.jobObjectId != '') {
          this.getApplicationsCalls(
            '',
            0,
            100,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            '',
            '',
            '',
            '',
            ''
          );
        }
      }

      // Update the previous processed application count
      this.previousProcessedApplicationCount = updatedProcessed || 0;

      // Stop the interval if counts are now equal and the extraction is completed
      if (
        updatedProcessed === updatedReceived &&
        this.processStateDetail === 'EXTRACTION_COMPLETED' &&
        this.isExtractionCompleted
      ) {
        this.subscription?.unsubscribe();
        console.log('Processing complete; interval stopped.');
      }
    });
  }

  calculateProcessingPercentage(
    applicationCount: number,
    processedApplicationCount: number
  ): number {
    if (applicationCount === 0) return 0;
    return Math.floor((processedApplicationCount / applicationCount) * 100);
  }
  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
    document.body.classList.add('bg-white');
    document.body.classList.add('desktop-view');
  }
  ngOnDestroy() {
    this.isProfile = false;
    this.showUpload = false;
    this.applicationMgr.all_applications = [];
    this.applicationMgr.not_qualified = [];
    this.applicationMgr.pre_qualified = [];
    this.applicationMgr.qualified = [];
    this.applicationMgr.reject = [];
    this.applicationMgr.final_decision = [];
    this.applicationMgr.hiringMangerSelections = [];
    this.applicationMgr.screeningStepsOptions = [];
    this.advancedStepId = '';
    this.applicationIds = [];
    this.application_obj_id = '';
    this.jobObjectId = '';
    this.processStateDetail = '';
    this.received_application_count = 0;
    this.processed_application_count = 0;
    this.jobStatus = '';
    this.processingPercentage = 0;
    this.previousProcessedApplicationCount = 0;
    this.minScore = 0;
    this.maxScore = 100;
    this.subscriptions.unsubscribe();
    this.webSocketService.closeWebSocket();
    document.body.classList.remove('bg-white');
    document.body.classList.remove('desktop-view');
    this.candidateId = '';
    this.candidateMgr.applicationProfile = {} as candidates;
    this.user = {};
    this.isSearchValue = '';
    this.isExtractionCompleted = false;
    if (this.applicationMgr.currentCounts) {
      this.applicationMgr.currentCounts.process_status = '';
    }
    this.subscription?.unsubscribe();
  }
}
