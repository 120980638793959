import { Injectable } from '@angular/core';
import { JobsService } from '../Services/jobs.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  jobs,
  applicationStatus,
  varaiblesModal,
  stages,
} from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class JobsManagerService {
  public isVisibleImportJob: boolean = false;
  public totalActive: number = 0;
  public totalActiveHome: number = 0;
  public latestJobCount: number = 0;
  public totalClosed: number = 0;
  public totalOther: number = 0;
  public activePage = 1;
  public closedPage = 1;
  public otherPage = 1;
  public homeJobPage = 1;
  public activeStatus: string = '';
  public statusActive: string = 'open';
  public statusClosed: string = 'closed';
  public statusOther: string = 'other';
  public jobStatus: string = 'open';
  nextPageUrl: string | null = null;
  public partnerIDActive: string = '';
  public partnerIDClosed: string = '';
  public partnerIDOther: string = '';
  public searchActive: string = '';
  public searchClosed: string = '';
  public searchOther: string = '';
  public isLoading: boolean = false;
  public isLoadingModalJobs: boolean = false;
  public isLoadingClosed: boolean = false;
  public isLoadingOther: boolean = false;
  public isLoadingImportJobs: boolean = false;
  public isLoadingApplicationStatus: boolean = false;
  public isLoadingImportingJobs: boolean = false;
  public selectJobModal: boolean = false;
  public jobsActiveData: jobs[] = [];
  public jobsActiveDataHome: jobs[] = [];
  public jobsClosedData: jobs[] = [];
  public jobsOtherData: jobs[] = [];
  public importJobsData: jobs[] = [];
  public applicationStatus: applicationStatus[] = [];
  private jobIdsSetIntegration: Set<number> = new Set<number>();
  private jobIdsSetDirect: Set<number> = new Set<number>();

  public isEnabledFeature: boolean = false;
  public userJobAccessStatus: string = '';
  public checkingUserJobStatus: boolean = false;
  public loadingJobRequirementList: boolean = false;
  public updatingJobRequirementList: boolean = false;
  public variables!: varaiblesModal | null;
  public jobRequirmentFeilds: any;
  public isLoadingJobFlow: boolean = false;
  public stages!: stages | null;
  public resetGlobalFlowModal: boolean = false;
  public selectedIndex = 0;
  public isConfirmStageChangeModal: boolean = false;
  public jobStatusName: string = 'open';
  constructor(
    public jobsSevice: JobsService,
    private notification: NzNotificationService
  ) {}
  public getActiveJobs(
    page: number,
    partnerIDActive: string,
    searchActive: string,
    statusActive: string
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.jobsSevice
        .getActiveJobs(page, partnerIDActive, searchActive, statusActive)
        .subscribe((c: any) => {
          this.jobsActiveData = c.results;
          this.totalActive = Object(c)['count'];
          this.isLoading = false;
          resolve();
        });
    });
  }

  public getActiveJobsHome(page: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingModalJobs = true;
      this.jobsSevice.getActiveJobsHome(page).subscribe((c: any) => {
        const newData = c.results;

        // Update or insert new jobs without clearing the array
        newData.forEach((newJob: any) => {
          const index = this.jobsActiveDataHome.findIndex(
            (job) => job._id === newJob._id
          );
          if (index !== -1) {
            // If the job already exists, update it
            this.jobsActiveDataHome[index] = newJob;
          } else {
            // If the job doesn't exist, add it to the array
            this.jobsActiveDataHome.push(newJob);
          }
        });

        this.totalActiveHome = Object(c)['count'];
        this.latestJobCount = Object(c)['latest_jobs_count'];
        this.nextPageUrl = Object(c)['next'];
        this.isLoadingModalJobs = false;
        resolve();
      });
    });
  }

  public getClosedJobs(
    page: number,
    partnerIDClosed: string,
    searchClosed: string,
    statusClosed: string
  ) {
    this.isLoadingClosed = true;
    this.jobsSevice
      .getActiveJobs(page, partnerIDClosed, searchClosed, statusClosed)
      .subscribe((c: any) => {
        this.jobsClosedData = c.results;
        this.totalClosed = Object(c)['count'];
        this.isLoadingClosed = false;
      });
  }
  public getOtherJobs(
    page: number,
    partnerIDOther: string,
    searchOther: string,
    statusOther: string
  ) {
    this.isLoadingOther = true;
    this.jobsSevice
      .getActiveJobs(page, partnerIDOther, searchOther, statusOther)
      .subscribe((c: any) => {
        this.jobsOtherData = c.results;
        this.totalOther = Object(c)['count'];
        this.isLoadingOther = false;
      });
  }
  public getImportJobs(status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobs(status).subscribe((c: any) => {
      this.importJobsData = c.response;
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsClosed(status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobs(status).subscribe((c: any) => {
      const newJobs = c.response.filter(
        (job: any) => !this.jobIdsSetDirect.has(job.remote_id)
      );
      this.importJobsData = [...this.importJobsData, ...newJobs];
      newJobs.forEach((job: any) => this.jobIdsSetDirect.add(job.remote_id));
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsATS(id: number, status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobsATS(id, status).subscribe((c: any) => {
      this.importJobsData = c.response;
      this.isLoadingImportJobs = false;
    });
  }
  public getImportJobsATSClosed(id: number, status: string) {
    this.isLoadingImportJobs = true;
    this.jobsSevice.getImportJobsATS(id, status).subscribe((c: any) => {
      const newJobs = c.response.filter(
        (job: any) => !this.jobIdsSetIntegration.has(job.remote_id)
      );
      this.importJobsData = [...this.importJobsData, ...newJobs];
      newJobs.forEach((job: any) =>
        this.jobIdsSetIntegration.add(job.remote_id)
      );
      this.isLoadingImportJobs = false;
    });
  }

  public importJobs(jobs: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingImportingJobs = true;
      this.jobsSevice.importJobs(jobs).subscribe({
        next: async (res: any) => {
          this.isVisibleImportJob = false;
          this.notification.create(
            'success',
            'New Job Added',
            'A job has been added and is processing. You will be notified when it is ready to view.'
          );
          //this.selectJobModal = false;
          this.isLoadingImportingJobs = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoadingImportingJobs = false;
          reject(error);
          //this.selectJobModal = false;
        },
      });
    });
  }
  public getApplicatonStatus(id: number) {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.getApplicatonStatus(id).subscribe((c: any) => {
      this.applicationStatus = c.response;
      this.isLoadingApplicationStatus = false;
    });
  }
  public getFollowUpFeature() {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.getFollowUpFeature().subscribe((c: any) => {
      this.isEnabledFeature = c.response.is_enabled;
      this.isLoadingApplicationStatus = false;
    });
  }
  public updatedFollowUpFeature(data: any) {
    this.isLoadingApplicationStatus = true;
    this.jobsSevice.updatedFollowUpFeature(data).subscribe((c: any) => {
      console.log(c.response);
      this.isEnabledFeature = c.response.is_enabled;
      this.isLoadingApplicationStatus = false;
    });
  }
  public submitApplicatonStatus(id: number, formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.jobsSevice.submitApplicatonStatus(id, formData).subscribe({
        next: async (res: any) => {
          this.notification.create('success', 'Application Status Updated', '');
          this.getApplicatonStatus(id);
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.response);
          }
          reject(error);
        },
      });
    });
  }
  public candidatesProcessing(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.jobsSevice.candidatesProcessing(id).subscribe({
        next: async (res: any) => {
          this.notification.create(
            'success',
            'Candidate Processing Started',
            ''
          );
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.response);
          }
          reject(error);
        },
      });
    });
  }
  public checkUserJobRequest(id: string) {
    this.checkingUserJobStatus = true;
    this.jobsSevice.checkUserJobRequest(id).subscribe((c: any) => {
      this.userJobAccessStatus = Object(c.response)['status']
        ? Object(c.response)['status']
        : 'not-requested';
      console.log('status', this.userJobAccessStatus);
      this.checkingUserJobStatus = false;
    });
  }
  public requestUserJobRefinementAccess(id: string) {
    this.checkingUserJobStatus = true;
    this.jobsSevice.requestUserJobRefinementAccess(id).subscribe((c: any) => {
      this.checkUserJobRequest(id);
      this.notification.create('success', 'Request Sent!', '');
      this.checkingUserJobStatus = false;
    });
  }
  public getJobRequirmentListing(id: string) {
    this.loadingJobRequirementList = true;
    this.jobsSevice.getJobRequirmentListing(id).subscribe((c: any) => {
      if (c.response) {
        this.variables = c.response;
        //console.log(this.variables);
      }
      this.loadingJobRequirementList = false;
    });
  }
  public getJobRequirmentFeilds(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.loadingJobRequirementList = true;
      this.jobsSevice.getJobRequirmentListing(id).subscribe((c: any) => {
        if (c.response) {
          this.jobRequirmentFeilds = {};
          this.jobRequirmentFeilds = c.response;
        }
        this.loadingJobRequirementList = false;
        resolve();
      });
    });
  }
  public updateJobRequirmentFeilds(id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.updatingJobRequirementList = true;
      this.jobsSevice.updateJobRequirmentFeilds(id, data).subscribe({
        next: (c: any) => {
          this.getJobRequirmentFeilds(id);
          this.updatingJobRequirementList = false;
          this.getJobRequirmentListing(id);
          this.notification.create(
            'success',
            'New Job Added',
            'Job Settings Updated Successfully!'
          );
          resolve();
        },
        error: (error: any) => {
          this.updatingJobRequirementList = false;
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          reject(error);
        },
      });
    });
  }

  public getPartnerJobFlow(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingJobFlow = true;
      this.jobsSevice.getPartnerJobFlow().subscribe((c: any) => {
        this.stages = c.response;
        this.isLoadingJobFlow = false;
        resolve();
      });
    });
  }

  public updatePartnerJobFlow(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingJobFlow = true;
      this.jobsSevice.updatePartnerJobFlow(data).subscribe({
        next: (c: any) => {
          this.getPartnerJobFlow();
          this.notification.create(
            'success',
            'Updated',
            'Job Settings Updated Successfully!'
          );
          this.isLoadingJobFlow = false;
          resolve();
        },
        error: (error: any) => {
          this.isLoadingJobFlow = false;
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          reject(error);
        },
      });
    });
  }
  public getJobFlow(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingJobFlow = true;
      this.jobsSevice.getJobFlow(id).subscribe((c: any) => {
        this.stages = c.response;
        this.isLoadingJobFlow = false;
        resolve();
      });
    });
  }
  public updateJobFlow(role: string, id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingJobFlow = true;
      this.jobsSevice.updateJobFlow(id, data).subscribe({
        next: (c: any) => {
          this.getJobFlow(id);
          this.notification.create(
            'success',
            'Updated',
            'Job Settings Updated Successfully!'
          );
          if (role !== 'Recruiter') {
            this.resetGlobalFlowModal = true;
          }
          this.isLoadingJobFlow = false;
          resolve();
        },
        error: (error: any) => {
          this.isLoadingJobFlow = false;
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          reject(error);
        },
      });
    });
  }

  public updateJobFlowGlobal(id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingJobFlow = true;
      this.jobsSevice.updatePartnerJobFlow(data).subscribe({
        next: (c: any) => {
          this.getPartnerJobFlow();
          this.notification.create(
            'success',
            'Updated',
            'Job Settings Updated Successfully!'
          );
          this.resetGlobalFlowModal = false;
          this.isLoadingJobFlow = false;
          resolve();
        },
        error: (error: any) => {
          this.isLoadingJobFlow = false;
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          reject(error);
        },
      });
    });
  }
  public changeJobStage(
    stageName: string,
    id: string,
    data: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.jobsSevice.changeJobStage(id, data).subscribe({
        next: () => {
          this.jobStatusName = stageName;
          this.isConfirmStageChangeModal = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          reject(error);
        },
      });
    });
  }
}
