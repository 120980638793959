<div class="page">
    <main class="main" *ngIf="sjmMgr.jobInfo">
        <div class="logo-preview" *ngIf="sjmMgr.companyBasicData.appearance.logo">
            <img [src]="sjmMgr.companyBasicData.appearance.logo" alt="">
            <span>Powered by <strong>CLARA</strong></span>
        </div>
        <div *ngIf="sjmMgr.companyBasicData.appearance.logo === null">
            <div class="company-name"><strong>{{sjmMgr.jobInfo.partner_name}}</strong></div>
            <div class="heading-title">
                <h2>{{sjmMgr.jobInfo.title}}</h2>
                <div class="ml-10 font-med" *ngIf="jobObjId">JOB ID
                    {{jobObjId}}</div>
            </div>
        </div>
        <div class="buttons"
            *ngIf="sjmMgr.companyBasicData.about || sjmMgr.companyBasicData.appearance.logo || sjmMgr.companyBasicData.appearance.banner">
            <div class="btn-group md">
                <button [ngClass]="{ 'active': buttonJobApplication }"
                    [ngStyle]="buttonJobApplication ? { color: sjmMgr.companyBasicData.appearance.primary_text || '#000' } : {}">
                    Job Application
                </button>

                <button (click)="openAboutModal()" [ngClass]="{'active': buttonAbout}"
                    [ngStyle]="buttonAbout ? { color: sjmMgr.companyBasicData.appearance.primary_text || '#000' } : {}">
                    About {{sjmMgr.jobInfo.partner_name}}
                </button>
            </div>
        </div>
        <div class="white-panel">
            <div class="border-box">
                <p class="font-weight-medium text-center"
                    [ngStyle]="{ color: sjmMgr.companyBasicData.appearance.primary_text || '#000' }">Start your
                    application here</p>
                <div class="border-box-alert">
                    <div class="d-flex">
                        <div class="box-icon mr-16"
                            [ngStyle]="{ color: sjmMgr.companyBasicData.appearance.primary_text || '#000' }">
                            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                        </div>
                        <div class="d-flex">
                            <b>You are required to provide the following:</b>
                            <ul>
                                <li>Resume</li>
                                <ng-container *ngIf="sjmMgr.jobInfo?.job_materials_and_links?.materials">
                                    <ng-container *ngFor="let item of sjmMgr.jobInfo.job_materials_and_links.materials">
                                        <li *ngIf="item.is_display">
                                            {{ item.material_name | titlecase }}
                                        </li>
                                    </ng-container>
                                </ng-container>

                            </ul>
                        </div>

                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div>
                        <a [routerLink]="['/my-applications', jobObjId]" [queryParams]="{ token : token  }">
                            <button class="button-custom" [ngStyle]="getButtonStyles()">Start my application
                                process</button>
                        </a>

                        <div class="powred-by"><span>Powered by <strong>CLARA</strong></span></div>
                    </div>

                </div>
            </div>
            <div class="job-detail color-black-container">
                <div class="left-panel">
                    <div class="heading-title" *ngIf="sjmMgr.companyBasicData.appearance.logo">
                        <h2>{{sjmMgr.jobInfo.title}}</h2>
                        <div class="ml-10 font-med" *ngIf="jobObjId">JOB ID
                            {{jobObjId}}</div>
                    </div>
                    <p><b>About the job</b></p>
                    <div class="formated-description"
                        [innerHTML]="replaceEscapeSequences(sjmMgr.jobInfo.formatted_description ? sjmMgr.jobInfo.formatted_description :sjmMgr.jobInfo.description)">
                    </div>
                </div>
            </div>
        </div>
        <nz-modal [(nzVisible)]="isVisibleAboutModal" nzTitle="About Page Preview" (nzOnCancel)="handleAboutCancel()"
            [nzFooter]="null" [nzBodyStyle]="{ 'height': '95vh', 'overflow-y': 'auto' }" [nzStyle]="{ top: '0' }"
            nzWidth="768px" nzClassName="no-border-radius" [nzMaskClosable]="false">
            <ng-container *nzModalContent>

                <div class="logo-preview" *ngIf="sjmMgr.companyBasicData.appearance.logo">
                    <img [src]="sjmMgr.companyBasicData.appearance.logo" alt="">
                    <span>Powered by <strong>CLARA</strong></span>
                </div>
                <div class="buttons mb-20">
                    <div class="btn-group md">
                        <button (click)="handleAboutCancel()" [ngClass]="{ 'active': buttonJobApplication }"
                            [ngStyle]="buttonJobApplication ? { color: sjmMgr.companyBasicData.appearance.primary_text || '#5822d3' } : {}">
                            Job Application
                        </button>

                        <button [ngClass]="{'active': buttonAbout}"
                            [ngStyle]="buttonAbout ? { color: sjmMgr.companyBasicData.appearance.primary_text || '#5822d3' } : {}">
                            About {{sjmMgr.jobInfo.partner_name}}
                        </button>
                    </div>
                </div>
                <div class="banner-preview" *ngIf="sjmMgr.companyBasicData.appearance.banner">
                    <img [src]="sjmMgr.companyBasicData.appearance.banner" alt="">
                </div>
                <div class="company-about" *ngIf="sjmMgr.companyBasicData.about"
                    [innerHTML]="colorToLinks(sjmMgr.companyBasicData.about,sjmMgr.companyBasicData.appearance.secondary_color || '#5822d3' )">
                </div>
            </ng-container>
        </nz-modal>
    </main>
</div>