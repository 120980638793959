<div class="page">
    <app-side-menu *appIsGranted="{ content_type: 'application', desiredPermission: 'view' }"></app-side-menu>
    <main class="main" *ngIf="followUpMgr.jobDetailData">
        <nz-breadcrumb *appIsGranted="{ content_type: 'application', desiredPermission: 'view' }">
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a routerLink="/">Jobs</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>{{followUpMgr.jobDetailData.title}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="d-flex align-middle">
            <h1>{{followUpMgr.jobDetailData.title}}</h1>
            <div class="ml-10" *ngIf="followUpMgr.jobDetailData.remote_id">JOB ID
                {{followUpMgr.jobDetailData.remote_id}}</div>
        </div>

        <div class="follow-up" *ngIf="followUpMgr.questions.length > 0">
            <div class="alert-material">
                <div class="alert-left">
                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.5 29.333C9.136 29.333 3.167 23.364 3.167 16S9.137 2.667 16.5 2.667c7.364 0 13.334 5.969 13.334 13.333S23.864 29.333 16.5 29.333zm-1.329-8 9.427-9.428-1.886-1.885-7.541 7.543-3.772-3.772-1.885 1.885 5.657 5.657z"
                            fill="#12D382" />
                    </svg>
                    <div class="ml-8">Your application was received!</div>
                </div>
                <div class="alert-right">
                    <button class="btn-alert-material" (click)="visibleMaterialModal()">View application
                        materials</button>
                </div>
            </div>
            <div class="purple-box">
                <div class="purple-box-header">
                    Follow Up Questions
                </div>
                <div class="purple-box-body">
                    <p>Your application materials look great! We have some follow up questions in the categories below
                        that
                        can enhance your application.</p>
                    <div class="questions-categories">
                        <div class="question-item" *ngFor="let item of followUpMgr.questions; let i = index">
                            <span class="ico-info" nz-icon nzType="check" nzTheme="outline"></span>
                            <span>{{item.category}}</span>
                        </div>
                        <div class="question-item"
                            *ngIf="followUpMgr.additional_insights && followUpMgr.additional_insights.length > 0">
                            <span class="ico-info" nz-icon nzType="check" nzTheme="outline"></span>
                            <span>Additional insights</span>
                        </div>

                        <button (click)="openQuestionsModal(followUpMgr.questions)" nz-button nzSize="large"
                            nzType="primary" nzShape="round" class="mt-10">
                            Go to Questions</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="job-detail color-black-container" *ngIf="followUpMgr.jobDetailData">
            <div class="left-panel">
                <p><b>About the job</b></p>
                <div class="formated-description"
                    [innerHTML]="replaceEscapeSequences(followUpMgr.jobDetailData.formatted_description ? followUpMgr.jobDetailData.formatted_description :followUpMgr.jobDetailData.description)">
                </div>
            </div>
        </div>
        <nz-modal [(nzVisible)]="followUpMgr.isVisibleQuestionsModal" nzTitle="Follow Up Questions"
            (nzOnCancel)="handleCancel()" nzWidth="768px" [nzStyle]="{ top: '0' }"
            nzClassName="no-border-radius centered-title-modal" [nzFooter]="modalFooter" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <form nzLayout="vertical" nz-form [formGroup]="formGroup" class="multi-form">
                    <div>
                        <div *ngIf="followUpMgr.has_answers_submitted">
                            <nz-alert nzType="info" nzMessage="Thank you for submitting answers to your follow-up questions. Your responses have been
                            successfully submitted and cannot
                            be edited at this time."></nz-alert>
                            <br><br>
                        </div>
                        <div class="steps-box">
                            <div class="step-dot" *ngFor="let question of followUpMgr.questions; let i = index"
                                (click)="goToStep(i)" [class.active]="currentStep === i"></div>
                            <div class="step-dot" (click)="goToStep(followUpMgr.questions.length)"
                                [class.active]="currentStep === followUpMgr.questions.length"></div>
                            <div class="step-dot" (click)="goToStep(followUpMgr.questions.length + 1)"
                                [class.active]="currentStep === followUpMgr.questions.length + 1"></div>
                        </div>
                        <nz-spin [nzSpinning]="this.followUpMgr.isSubmiting">
                            <ng-container *ngFor="let item of followUpMgr.questions; let i = index">
                                <ng-container *ngIf="currentStep === i">
                                    <div>
                                        <h2 class="font-heading">{{ item.category | titlecase }}</h2>

                                        <ng-container *ngFor="let question of item.questions; let num = index">
                                            <!-- {{question | json}} -->
                                            <nz-form-item>
                                                <div class="custom-label">
                                                    <strong>Question {{num+1}}:</strong>
                                                    <ng-container
                                                        *ngFor="let line of question.question.split('\n'); let i = index">
                                                        <strong [ngClass]="{'indent': i !== 0}">{{ line }}
                                                        </strong> <br>
                                                    </ng-container>
                                                </div>

                                                <!-- Render different inputs based on the question type -->
                                                <!-- Short, Long, Free Text Questions -->

                                                <ng-container
                                                    *ngIf="question.type === 'short' || question.type === 'long' || question.type === 'free_text'">

                                                    <nz-form-control>
                                                        <nz-textarea-count [nzMaxCharacterCount]="1000">
                                                            <textarea nz-input placeholder="Type your answer"
                                                                [formControlName]="'question_' + question._id"
                                                                [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                                                                [ngClass]="{'texarea-disabled': question.answer.length > 0 || followUpMgr.has_answers_submitted}"></textarea>
                                                        </nz-textarea-count>
                                                    </nz-form-control>
                                                </ng-container>

                                                <!-- Yes/No Questions -->
                                                <ng-container *ngIf="question.type === 'yes_no'">
                                                    <nz-radio-group [formControlName]="'question_' + question._id">
                                                        <label nz-radio nzValue="Yes"
                                                            [nzDisabled]="question.answer.length > 0 || followUpMgr.has_answers_submitted">Yes</label>
                                                        <label nz-radio nzValue="No"
                                                            [nzDisabled]="question.answer.length > 0 || followUpMgr.has_answers_submitted">No</label>
                                                    </nz-radio-group>
                                                </ng-container>
                                                <ng-container *ngIf="question.type === 'multi_options' && question._id">
                                                    <!-- Multi Options (Single Select) -->

                                                    <ng-container *ngIf="question.config.is_multi_select == false">

                                                        <nz-radio-group [formControlName]="'question_' + question._id">
                                                            <label nz-radio *ngFor="let option of question.options"
                                                                [nzValue]="option.number"
                                                                [nzDisabled]="question.answer.length > 0 || followUpMgr.has_answers_submitted">
                                                                {{ option.option }}
                                                            </label>
                                                        </nz-radio-group>
                                                    </ng-container>



                                                    <ng-container *ngIf="question.config.is_multi_select == true">
                                                        <div [formArrayName]="'question_' + question._id">
                                                            <div
                                                                *ngFor="let option of question.options; let idx = index">
                                                                <label nz-checkbox [formControlName]="idx"
                                                                    [nzDisabled]="question.answer.length > 0 || followUpMgr.has_answers_submitted">
                                                                    {{ option.option }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </ng-container>


                                                    <ng-container *ngIf="question.config.is_user_entered === true">
                                                        <nz-form-control>

                                                            <textarea nz-input placeholder="Type your answer"
                                                                [formControlName]="'custom_' + question._id"
                                                                [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                                                                [ngClass]="{'texarea-disabled': question.answer.length > 0 || followUpMgr.has_answers_submitted}"></textarea>

                                                        </nz-form-control>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="question.type === 'scale'">
                                                    <nz-radio-group nzButtonStyle="solid"
                                                        [formControlName]="'question_' + question._id">
                                                        <label nz-radio-button
                                                            *ngFor="let scale of ['1', '2', '3', '4', '5']"
                                                            [nzValue]="scale"
                                                            [nzDisabled]="question.answer.length > 0 || followUpMgr.has_answers_submitted">
                                                            {{ scale }}
                                                        </label>
                                                    </nz-radio-group>
                                                </ng-container>


                                            </nz-form-item>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="currentStep === followUpMgr.questions.length">
                                <h2 class="font-heading">Additional Insights</h2>
                                <ng-container *ngIf="followUpMgr.additional_insights">
                                    <ng-container
                                        *ngFor="let question of followUpMgr.additional_insights; let num = index">

                                        <nz-form-item>
                                            <div class="custom-label">
                                                <strong>Question {{num+1}}:</strong>
                                                <ng-container
                                                    *ngFor="let line of question.question.split('\n'); let i = index">
                                                    <strong [ngClass]="{'indent': i !== 0}">{{ line }}</strong> <br>
                                                </ng-container>
                                            </div>


                                            <nz-form-control>
                                                <nz-radio-group [formControlName]="'question_' + question._id">
                                                    <label nz-radio
                                                        *ngFor="let option of question.options; let i = index"
                                                        [nzValue]="option.number"
                                                        [nzDisabled]="(question.answers && question.answers.length > 0) || followUpMgr.has_answers_submitted">
                                                        {{ option.option }}
                                                    </label>
                                                </nz-radio-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>

                                <nz-alert *ngIf="followUpMgr.additional_insights === null" nzType="info"
                                    nzMessage="Additional questions are not available. You may proceed with the other questions."
                                    nzShowIcon></nz-alert>
                            </ng-container>
                            <ng-container *ngIf="currentStep === followUpMgr.questions.length + 1">
                                <h2 class="font-heading">Review and Submit</h2>
                                <!-- Loop through each category -->

                                <ng-container *ngFor="let item of followUpMgr.questions; let i = index">
                                    <div>
                                        <h3><strong>{{ item.category | titlecase }}</strong></h3>

                                        <!-- Loop through each question in the category -->
                                        <ng-container *ngFor="let question of item.questions; let num = index">
                                            <nz-form-item>
                                                <div class="editable-question-option">
                                                    <div>
                                                        <strong>Question {{ num + 1 }}:</strong>
                                                        <ng-container
                                                            *ngFor="let line of question.question.split('\n'); let i = index">
                                                            <strong [ngClass]="{ 'indent': i !== 0 }">{{ line
                                                                }}</strong>
                                                            <br>
                                                        </ng-container>
                                                    </div>
                                                    <div class="ico-white cursor-pointer" (click)="goToStep(i)"><svg
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
                                                                fill="#1C1A20" fill-opacity=".6" />
                                                        </svg>
                                                    </div>

                                                </div>

                                                <div class="answer">
                                                    <strong>Answer:&nbsp;</strong>

                                                    <span *ngIf="question?.config?.is_multi_select">
                                                        <ng-container
                                                            *ngIf="getSelectedAnswers(question); else noAnswerMulti">
                                                            {{ getSelectedAnswers(question) }}
                                                        </ng-container>
                                                        <ng-template #noAnswerMulti>
                                                            <span class="color-red">No Answer provided</span>
                                                        </ng-template>
                                                    </span>
                                                    <ng-container
                                                        *ngIf="question.config && question?.config?.is_multi_select === false">
                                                        <span>{{otherRadio(question,formGroup.get('question_' +
                                                            question._id)?.value)}}</span>
                                                    </ng-container>

                                                    <span *ngIf="question?.config?.is_user_entered">
                                                        <span>, {{ getUserEnteredAnswer(question) }}</span>
                                                    </span>

                                                    <ng-container
                                                        *ngIf="formGroup.get('question_' + question._id)?.value && !question?.config?.is_multi_select; else noAnswer">
                                                        <span>{{ geGeneralAnswer(question) }}</span>
                                                    </ng-container>

                                                    <ng-template #noAnswer>
                                                        <span
                                                            *ngIf="!question?.config?.is_user_entered && !question?.config?.is_multi_select"
                                                            class="color-red">No Answer provided</span>
                                                    </ng-template>
                                                </div>


                                            </nz-form-item>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <!-- Loop through Additional Insights -->
                                <ng-container *ngIf="followUpMgr.additional_insights">
                                    <h3><strong>Additional Insights</strong></h3>
                                    <ng-container
                                        *ngFor="let question of followUpMgr.additional_insights; let num = index">
                                        <nz-form-item>
                                            <div class="editable-question-option">
                                                <div>
                                                    <strong>Question {{ num + 1 }}:</strong>
                                                    <ng-container
                                                        *ngFor="let line of question.question.split('\n'); let i = index">
                                                        <strong [ngClass]="{ 'indent': i !== 0 }">{{ line }}</strong>
                                                        <br>
                                                    </ng-container>
                                                </div>
                                                <div class="ico-white cursor-pointer"
                                                    (click)="goToStep(followUpMgr.questions.length)"><svg width="24"
                                                        height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
                                                            fill="#1C1A20" fill-opacity=".6" />
                                                    </svg>
                                                </div>

                                            </div>

                                            <div class="answer">

                                                <strong>Answer:&nbsp;</strong>
                                                <span
                                                    *ngIf="formGroup.get('question_' + question._id)?.value; else noAdditionalAnswer">
                                                    {{ getSelectedOption(question) || formGroup.get('question_' +
                                                    question._id)?.value }}
                                                </span>
                                                <ng-template #noAdditionalAnswer><span class="color-red">No Answer
                                                        provided</span></ng-template>

                                            </div>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </nz-spin>
                    </div>

                </form>
            </ng-container>
            <ng-template #modalFooter>
                <div class="flex-buttons" *ngIf="!followUpMgr.has_answers_submitted">
                    <button nz-button nzType="default" nzSize="large" [disabled]="currentStep === 0"
                        (click)="prevStep()" nzShape="round" class="mr-8">Back</button>
                    <button nz-button nzType="default" nzSize="large"
                        *ngIf="currentStep === followUpMgr.questions.length" (click)="skipAdditional()" nzShape="round"
                        class="mr-8">Skip</button>
                    <button (click)="nextStep()" nz-button nzSize="large" nzType="primary" nzShape="round">
                        {{currentStep === followUpMgr.questions.length + 1 ? 'Submit' : 'Next'}}
                    </button>

                </div>

                <div *ngIf="followUpMgr.has_answers_submitted">
                    <div class="flex-buttons">
                        <button nz-button nzType="link" nzSize="large"
                            (click)="cancelFollowUpQuestion()">Cancel</button>
                        <button (click)="nextStep()" nz-button nzSize="large" nzType="primary" nzShape="round" *ngIf="currentStep !==
                            followUpMgr.questions.length + 1 ">Next</button>

                        <button nz-button nzType="primary" nzSize="large"
                            *ngIf="followUpMgr.has_answers_submitted && currentStep === followUpMgr.questions.length + 1 && !user.id"
                            (click)="joinDein()" nzShape="round" class="ml-8">Join Clara</button>


                    </div>
                </div>
            </ng-template>
        </nz-modal>
        <nz-modal [(nzVisible)]="followUpMgr.successModal" nzTitle="" (nzOnCancel)="cancelSuccessModal()"
            [nzFooter]="null" nzWidth="400px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="success-box">
                    <h3>Your answers were saved!</h3>
                    <h3>CLARA is committed to our applicant progress. We’re here to help you find roles where you can
                        grow,
                        excel and add value.
                    </h3>
                    <div *ngIf="age === 'above_18'">
                        <div *ngIf="!user.id">
                            <p>If you would like to be recommend you for other open positions, join us today!</p>
                            <button (click)="joinDein()" nz-button nzBlock class="mb-10" nzType="primary"
                                nzShape="round">Join
                                CLARA</button>
                            <p>By joining CLARA you will enjoy many benefits:</p>
                            <ul>
                                <li>Free membership</li>
                                <li>Automatic matching and referrals to open positions</li>
                            </ul>

                            <div>
                                <button (click)="joinDein()" nz-button nzBlock class="mb-10" nzType="primary"
                                    nzShape="round">Join
                                    CLARA</button>
                                <button nz-button nzBlock nzType="link" (click)="cancelSuccessModal()">No
                                    Thanks</button>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="user.id" (click)="backToHome()" nz-button nzBlock class="mb-10" nzType="primary"
                        nzShape="round">Back to
                        Home</button>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="isVisbileMaterial" nzTitle="" (nzOnCancel)="cancelMaterialModal()"
            nzTitle="Application Materials" [nzFooter]="null" nzWidth="572px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div *ngIf="followUpMgr.application && followUpMgr.application.application_materials.length > 0">
                    <div *ngFor="let material of followUpMgr.application.application_materials">
                        <div class="material-item">
                            <!-- Material icon -->
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 29.333C8.636 29.333 2.667 23.364 2.667 16S8.637 2.667 16 2.667c7.364 0 13.334 5.969 13.334 13.333S23.364 29.333 16 29.333zm-1.329-8 9.427-9.428-1.886-1.885-7.541 7.543-3.772-3.772-1.885 1.885 5.657 5.657z"
                                    fill="#12D382" />
                            </svg>


                            <div class="ml-8">{{ material.filename }}</div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="followUpMgr.application && followUpMgr.application.links && followUpMgr.application.links.length > 0">
                    <div *ngFor="let link of followUpMgr.application.links">
                        <div class="material-item">
                            <!-- Link icon -->
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m23.543 19.77-1.886-1.885L23.543 16A5.334 5.334 0 0 0 16 8.457l-1.885 1.886-1.886-1.886 1.886-1.885a8 8 0 0 1 11.313 11.313l-1.885 1.886zm-3.772 3.773-1.886 1.885A8 8 0 1 1 6.572 14.115l1.885-1.886 1.886 1.886L8.457 16A5.333 5.333 0 1 0 16 23.543l1.885-1.886 1.886 1.886zm0-13.2 1.886 1.886-9.428 9.427-1.886-1.885 9.428-9.427v-.001z"
                                    fill="#1C1A20" fill-opacity=".7" />
                            </svg>

                            <div class="ml-8">{{ link.link_url }}</div>
                        </div>
                    </div>

                    <div class="footer-right">
                        <button nz-button (click)="cancelMaterialModal()" nzType="default" nzShape="round">Done</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
        <nz-modal [(nzVisible)]="followUpMgr.isVisbileConfirmSubmit" [nzClosable]="false" [nzFooter]="null"
            nzWidth="572px" nzCentered [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="mini-modal">
                    <h2 class="font-heading">Submit</h2>
                    <div class="gray-box">
                        Please review your answers carefully before submitting. Answers can only be submitted once, and
                        any questions left blank
                        cannot be filled in later. Make sure all responses are complete before proceeding.
                    </div>
                    <div class="mini-footer">
                        <button nz-button nzType="text" nzSize="large" nzShape="round" class="mr-8"
                            (click)="handleCancelSubmitModal()">Cancel</button>
                        <button class="btn-theme-clara" (click)="submitAnswers()"
                            [disabled]="followUpMgr.isSubmiting"><span *ngIf="followUpMgr.isSubmiting" nz-icon
                                nzType="loading" nzTheme="outline"></span>Submit</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>

    </main>
</div>