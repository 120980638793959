<nz-spin [nzSpinning]="this.dashboardMgr.isLoadingDashboard">
    <div *ngIf="this.dashboardMgr.partners.length > 0">
        <div class="data-table">
            <nz-table [nzFrontPagination]="false" #data [nzData]="dashboardMgr.partners">
                <thead>
                    <tr>
                        <th scope="col" nzCustomFilter nzWidth="250px">
                            Partners
                            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0"
                                [nzDropdownMenu]="menuSearch">
                                <span nz-icon nzType="search"></span>
                            </nz-filter-trigger>
                        </th>
                        <th scope="col">Partner Status</th>
                        <th scope="col">Total Jobs</th>
                        <th scope="col">Active Jobs</th>
                        <th scope="col">Total Partner Users</th>
                        <th scope="col">Subscription Type</th>
                        <th scope="col">Applications</th>
                        <th scope="col">Not Qualified</th>
                        <th scope="col">Pre Qualified</th>
                        <th scope="col">Qualified</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of data.data" (click)="selectRow(data.partner_id)"
                        [class.selected-row]="data.partner_id === partnerId">
                        <td>
                            <span class="cell-title">{{ data.partner_name }}</span>
                        </td>
                        <td>
                            <span *ngIf="data.is_account_owner_active">Active</span>
                            <span *ngIf="data.is_account_owner_active === false">Inactive</span>
                            <span *ngIf="data.is_account_owner_active === null">No account owner exists</span>
                        </td>
                        <td>{{data.all_jobs_count}}</td>
                        <td>{{data.active_jobs_analytics?.current_count}}</td>
                        <td>{{data.partner_users}}</td>
                        <td>
                            <nz-tag *ngFor="let subscription of data.subscriptions">
                                {{ subscription.product }} - {{ subscription.status }}
                            </nz-tag>
                        </td>
                        <td>{{data.application_overview?.all_applications?.current_count}}
                        </td>
                        <td>{{data.application_overview?.not_qualified?.current_count}}
                        </td>
                        <td>{{data.application_overview?.pre_qualified?.current_count}}
                        </td>
                        <td>{{data.application_overview?.qualified?.current_count}}
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            <nz-dropdown-menu #menuSearch="nzDropdownMenu">
                <div class="ant-table-filter-dropdown">
                    <div class="search-box">
                        <input type="text" nz-input placeholder="Search by partner name" [(ngModel)]="searchValue" />
                        <button nz-button nzType="default" (click)="search()" class="search-button">Search</button>
                        <button nz-button nzType="link" (click)="reset()">Reset</button>
                    </div>
                </div>
            </nz-dropdown-menu>
            <div class="pagination">
                <nz-pagination *ngIf="dashboardMgr.totalPartners > 10" [nzPageIndex]="1"
                    (nzPageIndexChange)="handlePageIndexChange($event)"
                    [nzTotal]="dashboardMgr.totalPartners"></nz-pagination>
            </div>
        </div>
        <div nz-row [nzGutter]="15" *ngIf="isChartVisible">
            <div nz-col [nzSpan]="12">
                <div class="card" *ngIf="isChartVisible">
                    <div class="card-header">
                        <p>Total Applications</p>
                        <button nz-button nz-dropdown [nzDropdownMenu]="interval">
                            {{selectedTimeframe}}
                            <span nz-icon nzType="down"></span>
                        </button>
                        <nz-dropdown-menu #interval="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-menu-item (click)="getAnaylytics('weekly','Weekly')">Weekly</li>
                                <li nz-menu-item (click)="getAnaylytics('monthly','Monthly')">Monthly</li>
                                <li nz-menu-item (click)="getAnaylytics('all_time','All time')">All time</li>
                            </ul>
                        </nz-dropdown-menu>
                    </div>
                    <div class="card-subheader" *ngIf="dashboardMgr.applications">
                        <strong>
                            {{dashboardMgr.applications.last_total}}
                            <label class="new-counts">
                                <span class="new-counts-icon">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>
                                </span>
                                <span>{{dashboardMgr.applications.total}}</span>
                            </label>
                        </strong>
                    </div>
                    <div class="card-body" *ngIf="dashboardMgr.applications">
                        <div id="chartCandidates" *ngIf="dashboardMgr.applications.analytics.length > 0">
                            <apx-chart [series]="chartOptionsCandidates.series!" [chart]="chartOptionsCandidates.chart!"
                                [dataLabels]="chartOptionsCandidates.dataLabels!"
                                [plotOptions]="chartOptionsCandidates.plotOptions!"
                                [responsive]="chartOptionsCandidates.responsive!"
                                [xaxis]="chartOptionsCandidates.xaxis!" [legend]="chartOptionsCandidates.legend!"
                                [fill]="chartOptionsCandidates.fill!" [noData]="chartOptionsCandidates.noData!"
                                [stroke]="chartOptionsCandidates.stroke!" [tooltip]="chartOptionsCandidates.tooltip!"
                                [markers]="chartOptionsCandidates.markers!"></apx-chart>
                        </div>

                    </div>
                </div>
                <div nz-row [nzGutter]="15" class="mb-20">
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics text-center" *ngIf="dashboardMgr.active_jobs_analytics">
                            <p>{{dashboardMgr.active_jobs_analytics.current_count}}</p>
                            <div>Active Jobs</div>
                            <div class="description-bar">{{dashboardMgr.active_jobs_analytics.current_count -
                                dashboardMgr.active_jobs_analytics.last_count}}
                                <span class="arrow-icon"
                                    [ngClass]="{'down-trend': (dashboardMgr.active_jobs_analytics.current_count - dashboardMgr.active_jobs_analytics.last_count) < 0}"
                                    *ngIf="dashboardMgr.active_jobs_analytics.current_count - dashboardMgr.active_jobs_analytics.last_count !== 0">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>

                                </span> in the last 7 days
                            </div>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics">
                            <div class="flex-text">
                                <strong>Time Gained</strong>
                                <div>
                                    <p>{{ dashboardMgr.timeSaved.hours }}</p>
                                    <span>hours</span>
                                </div>
                                <div>
                                    <p>{{ dashboardMgr.timeSaved.minutes }}</p>
                                    <span>mins</span>
                                </div>
                            </div>
                            <nz-segmented [nzOptions]="options" [nzBlock]="true"
                                (nzValueChange)="handleTimeSavedChange($event)"></nz-segmented>
                        </div>
                    </div>

                </div>
            </div>
            <div nz-col [nzSpan]="12">
                <div class="card" *ngIf="isChartAssigneVisible">
                    <div class="card-header">
                        <p>Team Data</p>
                        <div class="d-flex">
                            <button nz-button nz-dropdown [nzDropdownMenu]="chartType">
                                {{selectedChartType}}
                                <span nz-icon nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #chartType="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item (click)="changeChartType('Applications')">Applications</li>
                                    <li nz-menu-item (click)="changeChartType('Jobs')">Jobs</li>
                                    <li nz-menu-item (click)="changeChartType('Job Lifecycle')">Job Lifecycle</li>
                                </ul>
                            </nz-dropdown-menu>
                            <div class="btn-group-sm">
                                <div [ngClass]="{'event-none': currentPage === 0}">
                                    <button (click)="previousPage()" [disabled]="currentPage === 0"><span nz-icon
                                            nzType="arrow-left" nzTheme="outline"></span></button>
                                </div>
                                <div [ngClass]="{'event-none': currentPage >= totalPages - 1}">
                                    <button (click)="nextPage()" [disabled]="currentPage >= totalPages - 1"><span
                                            nz-icon nzType="arrow-right" nzTheme="outline"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div id="chartAssigne" *ngIf="selectedChartType === 'Applications'">
                            <apx-chart [series]="chartOptionsAssigne.series!" [chart]="chartOptionsAssigne.chart!"
                                [dataLabels]="chartOptionsAssigne.dataLabels!"
                                [plotOptions]="chartOptionsAssigne.plotOptions!" [xaxis]="chartOptionsAssigne.xaxis!"
                                [legend]="chartOptionsAssigne.legend!" [fill]="chartOptionsAssigne.fill!"
                                [noData]="chartOptionsAssigne.noData!"></apx-chart>
                        </div>
                        <div id="chartAssigneJobs" *ngIf="selectedChartType === 'Jobs'">
                            <apx-chart [series]="chartOptionsAssigneJob.series!" [chart]="chartOptionsAssigneJob.chart!"
                                [dataLabels]="chartOptionsAssigneJob.dataLabels!"
                                [plotOptions]="chartOptionsAssigneJob.plotOptions!"
                                [xaxis]="chartOptionsAssigneJob.xaxis!" [legend]="chartOptionsAssigneJob.legend!"
                                [fill]="chartOptionsAssigneJob.fill!"
                                [noData]="chartOptionsAssigneJob.noData!"></apx-chart>
                        </div>
                        <div id="chartAssigneJobsLifecycle" *ngIf="selectedChartType === 'Job Lifecycle'">
                            <apx-chart [series]="chartOptionsAssigneJobLifecycle.series!"
                                [chart]="chartOptionsAssigneJobLifecycle.chart!"
                                [dataLabels]="chartOptionsAssigneJobLifecycle.dataLabels!"
                                [plotOptions]="chartOptionsAssigneJobLifecycle.plotOptions!"
                                [xaxis]="chartOptionsAssigneJobLifecycle.xaxis!"
                                [legend]="chartOptionsAssigneJobLifecycle.legend!"
                                [fill]="chartOptionsAssigneJobLifecycle.fill!"
                                [noData]="chartOptionsAssigneJobLifecycle.noData!"></apx-chart>
                        </div>
                    </div>
                </div>
                <div nz-row [nzGutter]="15">
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics text-center">
                            <span class="title-icon">
                                TBC
                                <span class="arrow-icon arrow-absolute">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>

                                </span>
                            </span>
                            <div>Increase in Pre-Qualified <br> CLARA candidates</div>

                        </div>
                    </div>
                    <div nz-col [nzSpan]="12">
                        <div class="card-statistics text-center">
                            <span class="title-icon">
                                {{dashboardMgr.avg_job_life}}
                                <span class="arrow-icon arrow-absolute">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.24375 8.5L0.5 7.75625L6.40625 1.85H1.2V0.800003H8.2V7.8H7.15V2.59375L1.24375 8.5Z" />
                                    </svg>

                                </span>
                            </span>
                            <div>Days</div>
                            <div><b>Avg Job Life</b></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- <nz-tabset (nzSelectChange)="onTabChange($event)" *ngIf="dashboardMgr.partners.length > 0">
            <nz-tab *ngFor="let partner of dashboardMgr.partners" [nzTitle]="partner.partner_name">
                <app-all-jobs-overview [data]="dashboardMgr.applicationOverview"></app-all-jobs-overview>
            </nz-tab>
        </nz-tabset> -->

    </div>

</nz-spin>