export class users {
  id: number = 0;
  user_id: number = 0;
  username: string = '';
  first_name: string = '';
  email: string = '';
  phone: string = '';
  role: string = '';
  is_active: boolean = false;
  user_profile?: user_profile;
  partner!: partner;
  isAssignedJob: boolean = false;
  profile_info!: profile_info;
  applications?: applications[] = [];
}
export class profile_info {
  email: string = '';
  first_name: string = '';
  last_name: string = '';
  remote_id: string = '';
  user_profile?: user_profile;
  website_addresses: website_addresses[] = [];
  addresses: addresses[] = [];
  phone_numbers: phone_numbers[] = [];
  user?: user;
  initials: string = '';
  applications: applications[] = [];
}

export class user {
  email: string = '';
  id: number = 0;
}
export class application {
  job_title: string = '';
  applied_at: string = '';
  remote_id: string = '';
  score: number = 0;
}
export class partner {
  id: number = 0;
  partner_name: string = '';
  phone_number: string = '';
}
export class user_profile {
  first_name: string = '';
  last_name: string = '';
  phone_number: string = '';
}
export class skillMapping {
  name: string = '';
  score: number = 0;
  maxScore: number = 0;
  summary: string = '';
}
export class partners {
  id: number = 0;
  partner_id: number = 0;
  all_jobs_count: number = 0;
  partner_users: number = 0;
  partner_name: string = '';
  created_datetime: string = '';
  is_account_owner_active: boolean | null = false;
  active_jobs_analytics?: active_jobs_analytics;
  application_overview?: application_overview;
  subscriptions: subscriptions[] = [];
}
export class subscriptions {
  product: string = '';
  status: string = '';
}
export class active_jobs_analytics {
  current_count: number = 0;
}
export class application_overview {
  all_applications?: all_applications;
  qualified?: qualified;
  pre_qualified?: pre_qualified;
  not_qualified?: not_qualified;
}
export class all_applications {
  current_count: number = 0;
}
export class qualified {
  current_count: number = 0;
}
export class pre_qualified {
  current_count: number = 0;
}
export class not_qualified {
  current_count: number = 0;
}
export class candidates {
  id: number = 0;
  _id: string = '';
  remote_id: string = '';
  first_name: string = '';
  last_name: string = '';
  is_bookmarked: boolean = false;
  candidate_name: string = '';
  score: number = 0;
  previous_score!: previous_score;
  email_addresses: email_addresses[] = [];
  phone_numbers: phone_numbers[] = [];
  addresses: addresses[] = [];
  website_addresses: website_addresses[] = [];
  jobs: jobs[] = [];
  jobs_applied: jobs_applied[] = [];
  applications: applications[] = [];
  follow_up_questions: follow_up_questions[] = [];
  comments: comments[] = [];
  resume_fields!: resume_fields;
  application_profile!: application_profile;
  ats: string = '';
  candidate_remote_id: number = 0;
  dein_candidate_id: number = 0;
  candidate_id: number = 0;
  candidate_obj_id: string = '';
  merge_id: string = '';
  initials: string = '';
  candidate_initials: string = '';
  candidate_ats_url: string = '';
  updated_with_answer: string = '';
  status: string = '';
  answers_summary: string = '';
  screening_segment: string = '';
  application_materials: application_materials[] = [];
  links: links[] = [];
  metrics!: metrics;
  is_followup_email_sent: boolean = false;
  is_resend_email_allowed: boolean = false;
  candidate_email_addresses: candidate_email_addresses[] = [];
}
export class previous_score {
  fit_score: number = 0;
  skill_mapping!: skill_mapping;
}
export class candidate_email_addresses {
  email: string = '';
}
export class links {
  link_url: string = '';
  link_name: string = '';
}
export class follow_up_questions {
  category: string = '';
  question: string = '';
  answer: string[] = [];
  options: options[] = [];
  type: string = '';
}
export class options {
  option: string = '';
  number: string = '';
}
export class application_materials {
  filename: string = '';
  material_name: string = '';
  url: string = '';
}
export class application_profile {
  summary: string = '';
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_traveled!: distance_traveled;
  skill_mapping!: skill_mapping;
  education: education[] = [];
  experience: experience[] = [];
  requirements_match: requirements_match[] = [];
}
export class skill_mapping {
  education_score: number = 0;
  nice_to_have_exp_score: number = 0;
  nice_to_have_skill_score: number = 0;
  people_skill_score: number = 0;
  required_exp_score: number = 0;
  required_skill_score: number = 0;
}
export class requirements_match {
  score: number = 0;
  job_description: any | JobDescriptionObject = '';
  percentage_score: number = 0;
  is_match: boolean = false;
}
export interface JobDescriptionObject {
  degree: string;
  level: string;
  cgpa: number;
}
export class phone_numbers {
  value: string = '';
}
export class addresses {
  value: string = '';
}
export class website_addresses {
  value: string = '';
}
export class email_addresses {
  value: string = '';
  email_addresses_type: string = '';
}
export class jobs {
  title: string = '';
  status: string = '';
  remote_id: string = '';
}
export class jobs_applied {
  title: string = '';
  status: string = '';
  remote_id: string = '';
}
export class applications {
  score: number = 0;
  _id: string = '';
  job!: job;
  remote_id: string = '';
  applied_at: string = '';
  profile?: profile;
  application_profile?: application_profile;
  job_title: string = '';
  job_status: string = '';
  metrics!: metrics;
}
export class metrics {
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_traveled!: distance_traveled;
}

export class profile {
  fit_score: number = 0;
  learning_agility!: learning_agility;
  critical_thinking!: critical_thinking;
  distance_traveled!: distance_traveled;
}

export class learning_agility {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
  level: string = '';
  is_answered: boolean = false;
}
export class critical_thinking {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
  level: string = '';
  is_answered: boolean = false;
}
export class distance_traveled {
  candidate_recommendation: string = '';
  partner_recommendation: string = '';
  is_answered: boolean = false;
}
export class job {
  remote_id: number = 0;
  _id: string = '';
  status: string = '';
  title: string = '';
  ats: string = '';
  posting_date: string = '';
}
export class comments {
  candidate: string = '';
  date_posted: string = '';
  text: string = '';
}
export class resume_fields {
  summary: string = '';
  linked_in: string = '';
  email: string = '';
  website: string = '';
  education: education[] = [];
  experience: experience[] = [];
}
export class education {
  institute: string = '';
  graduate_date: string = '';
  degree: string = '';
}
export class experience {
  company: string = '';
  position: string = '';
  start_date: string = '';
  end_date: string = '';
  description: string = '';
  level: string = '';
  duration: string = '';
}
export class planDetails {
  active: boolean = false;
  created: string = '';
  renew: string = '';
  marketing_features: marketing_features[] = [];
  name: string = '';
  url: string = '';
  customer_portal_url: string = '';
  threshold: string = '';
  status: string = '';
  stripe_customer: boolean = false;
  payment_method!: payment_method;
  invoices?: invoices;
  past_pay_as_go_usage: number = 0;
  monthly_overage_cost: number = 0;
  subscription_cost: number = 0;
}
export class invoices {
  data: data[] = [];
}
export class data {
  card!: card;
  number: string = '';
  created: number = 0;
  total: number = 0;
  status: string = '';
  invoice_pdf: string = '';
  billing_details?: billing_details;
}
export class card {
  brand: string = '';
  exp_month: string = '';
  exp_year: string = '';
  last4: string = '';
}

export class payment_method {
  data: data[] = [];
}
export class billing_details {
  address?: address;
  email: string | null = null;
  name: string | null = null;
  phone: string | null = null;
}
export class address {
  city: string | null = null;
  country: string | null = null;
  line1: string | null = null;
  line2: string | null = null;
  postal_code: string | null = null;
  state: string | null = null;
}
export class marketing_features {
  name: string = '';
}
export class plans {
  payment_link_name: string = '';
  payment_link_url: string = '';
  line_items: line_items[] = [];
}
export class line_items {
  amount_total: string = '';
  description: string = '';
  marketing_features: marketing_features[] = [];
}
export class candidatesFilters {
  applications_stages: applications_stages[] = [];
  jobs: jobs[] = [];
  jobs_statuses: string[] = [];
}
export class applications_stages {
  job_stage_id: string = '';
  job_stage_name: string = '';
}
