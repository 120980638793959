<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Subscription Settings</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="heading-flex">
            <h1>Subscription Settings</h1>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col>
                <nz-spin [nzSpinning]="userMgr.isLoadingSubscription">
                    <div class="white-box mb-16"
                        *ngIf="userMgr.isCustomerChecked && userMgr.planDetails && userMgr.isStripeCustomer">
                        <div class="customer-type">
                            <strong>Stripe Customer</strong>
                        </div>
                        <div class="header-box">
                            <div class="d-flex align-middle">
                                <div>Subscription plan:</div>
                                <div class="badge-info ml-8">
                                    {{userMgr.planDetails.name }}</div>
                            </div>
                            <div class="d-flex align-middle">
                                <nz-tag class="ico-badge">
                                    <nz-badge [nzColor]="true ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                    {{ userMgr.planDetails.status |
                                    titlecase }}
                                </nz-tag>
                                <span class="ico-sm ml-4" nz-tooltip
                                    nzTooltipTitle="Your account will be updated at the end of your payment cycle. You can use your account normally in the interim."
                                    nz-icon nzType="info-circle" [nzTooltipPlacement]="['topLeft', 'leftTop']"
                                    nzTheme="outline"></span>
                            </div>
                        </div>
                        <div class="body-box">
                            <p>Plan details:</p>
                            <ul class="packages-list" *ngIf="userMgr.planDetails.marketing_features.length > 0">
                                <li *ngFor="let package of userMgr.planDetails.marketing_features">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>{{package.name}}</span>
                                </li>
                            </ul>
                            <div class="timeline">
                                <p>Subscription Dates:</p>
                                <nz-timeline>
                                    <nz-timeline-item [nzDot]="dotTemplate"><span>Start
                                            Date: </span>{{userMgr.planDetails.created |
                                        date}}</nz-timeline-item>
                                    <nz-timeline-item [nzDot]="dotTemplate"><span>Renewal
                                            Date: </span>{{userMgr.planDetails.renew |
                                        date}}</nz-timeline-item>
                                    <ng-template #dotTemplate>
                                        <span nz-icon nzType="calendar" nzTheme="outline"></span>
                                    </ng-template>
                                </nz-timeline>
                            </div>

                            <a *ngIf="userMgr.planDetails.customer_portal_url"
                                [href]="userMgr.planDetails.customer_portal_url" target="_blank"> <button nz-button
                                    nzType="default" nzShape="round">Account Billing <span nz-icon nzType="arrow-right"
                                        nzTheme="outline"></span></button></a>
                        </div>
                        <div class="utilization" *ngIf="userMgr.planDetails.threshold !== null">
                            <span>Plan utilization</span>
                            <div class="processing-flex">
                                <div class="progress-bar">
                                    <div class="progress-fill"
                                        [ngStyle]="{'width': userMgr.planDetails.threshold  + '%'}"></div>
                                </div>
                                <div class="progress-value-count">{{userMgr.planDetails.threshold}}%</div>
                            </div>
                        </div>

                        <div class="footer-box" *ngIf="userMgr.planDetails.customer_portal_url">
                            <a [href]="userMgr.planDetails.customer_portal_url" target="_blank"><button nz-button
                                    nzType="primary" nzShape="round" class="mb-8">Edit Plan</button></a>
                            <a [href]="userMgr.planDetails.customer_portal_url" target="_blank"><button nz-button
                                    nzType="text" nzShape="round">Cancel Plan</button></a>
                        </div>
                    </div>
                    <div class="white-box" *ngIf="userMgr.isCustomerChecked && !userMgr.isStripeCustomer">
                        <div class="customer-type">
                            <strong>Non-Stripe Customer</strong>
                        </div>
                        <div class="header-box">
                            <div class="d-flex align-middle">
                                <div>Subscription plan:</div>
                                <div class="badge-info ml-8">
                                    Custom</div>
                            </div>
                            <div class="d-flex align-middle">
                                <nz-tag class="ico-badge">
                                    <nz-badge [nzColor]="true ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                    Active
                                </nz-tag>

                            </div>
                        </div>
                        <div class="body-box">
                            <p>Plan details:</p>
                            <ul class="packages-list">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Unlimited recruiter seats</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Unlimited hiring manager seats</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>50,000 application limit</span>
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M17.385 6.497a1.5 1.5 0 1 1 2.23 2.006l-9 10a1.5 1.5 0 0 1-2.176.058l-4-4a1.5 1.5 0 1 1 2.122-2.122l2.882 2.882 7.942-8.824z"
                                            fill="#0FD795" />
                                    </svg>
                                    <span>Supplemental packages</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nz-spin>
            </div>
            <div nz-col>
                <div class="white-box mb-16"
                    *ngIf="userMgr.isCustomerChecked && userMgr.planDetails && userMgr.isStripeCustomer">
                    <div class="header-box">
                        <div class="d-flex align-middle">
                            <h4>Payment Method</h4>
                        </div>
                    </div>
                    <div class="body-box">
                        <div class="light-box" *ngFor="let data of userMgr.planDetails.payment_method.data">
                            <div *ngIf="data.card; else noCardMessage">
                                <div class="card-type">
                                    <div class="ico-method">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.5 6.654a6.785 6.785 0 0 1 2.596 5.344A6.787 6.787 0 0 1 12.5 17.34a6.786 6.786 0 0 1-2.596-5.343A6.787 6.787 0 0 1 12.5 6.654zm-.87-.582A7.784 7.784 0 0 0 8.9 12a7.783 7.783 0 0 0 2.728 5.926 6.798 6.798 0 1 1 .003-11.854zm1.742 11.854A7.782 7.782 0 0 0 16.1 12a7.784 7.784 0 0 0-2.73-5.928 6.798 6.798 0 1 1 .003 11.854z"
                                                fill="#5822D3" />
                                        </svg>
                                    </div>
                                    <span>{{data.card.brand | titlecase}}</span>
                                </div>
                                <div class="card-number">
                                    **** **** **** {{data.card.last4}}
                                </div>
                                <div class="card-date">
                                    Expires on {{data.card.exp_month}}/{{data.card.exp_year}}
                                </div>
                            </div>
                            <ng-template #noCardMessage>
                                <div class="no-card-message">
                                    No card details available.
                                </div>
                            </ng-template>
                            <div class="info-card">
                                <div>
                                    <h3>Address</h3>
                                    <div><span>City:</span> {{
                                        data.billing_details?.address?.city || ''
                                        }}</div>
                                    <div><span>Country:</span> {{
                                        data.billing_details?.address?.country || ''
                                        }}</div>
                                    <div><span>Line 1:</span> {{
                                        data.billing_details?.address?.line1 || ''
                                        }}</div>
                                    <div><span>Line 2:</span> {{
                                        data.billing_details?.address?.line2 || ''
                                        }}</div>
                                    <div><span>Postal Code:</span> {{
                                        data.billing_details?.address?.postal_code || ''
                                        }}</div>
                                    <div><span>State:</span> {{
                                        data.billing_details?.address?.state || ''
                                        }}</div>
                                </div>
                                <div>
                                    <h3>Contact Information</h3>
                                    <div><span>Email:</span> {{
                                        data.billing_details?.email || ''
                                        }}</div>
                                    <div><span>Phone:</span> {{
                                        data.billing_details?.phone || ''
                                        }}</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="white-box"
                    *ngIf="userMgr.isCustomerChecked && userMgr.planDetails && userMgr.isStripeCustomer">
                    <div class="header-box">
                        <div class="d-flex align-middle">
                            <h4>Costs</h4>
                        </div>
                    </div>
                    <div class="body-box">
                        <div class="description-item">
                            <span>Subscription Cost</span>
                            <span>$ {{userMgr.planDetails.subscription_cost}}</span>
                        </div>
                        <div class="description-item" *ngIf="userMgr.planDetails.past_pay_as_go_usage">
                            <span>Monthly Overage Cost ({{userMgr.planDetails.past_pay_as_go_usage}})</span>
                            <span>$ {{userMgr.planDetails.monthly_overage_cost}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="billing-history"
            *ngIf="userMgr.isCustomerChecked && userMgr.planDetails && userMgr.isStripeCustomer">
            <h2>Billing History</h2>
            <table>
                <thead>
                    <tr>
                        <th>Invoice</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of userMgr.planDetails.invoices?.data">
                        <td>{{data.number}}</td>
                        <td>{{ getFormattedDate(data.created) | date: 'medium' }}</td>

                        <td>$ {{convertCents(data.total)}}</td>
                        <td><button class="staus-badge">{{data.status | titlecase}}</button></td>
                        <td><a nz-button nzType="link" [href]="data.invoice_pdf" target="_blank"
                                rel="noopener noreferrer">Download Invoice</a>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </main>
</div>