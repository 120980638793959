<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>Users</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="heading-flex">
            <h1 class="font-heading">Users</h1>
            <button *appIsGranted="{ content_type: 'partnersettings', desiredPermission: 'view' }" nz-button
                nzType="primary" nzShape="round" class="font-size-medium" (click)="showModalAddUser()"><span nz-icon
                    nzType="plus" nzTheme="outline"></span> Add
                New User</button>
        </div>

        <div class="fiter-form">
            <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="6">
                    <form nz-form [formGroup]="searchFormUser" (ngSubmit)="submitSearchUser()">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                            <input type="text" (input)="onSearchInputUser()" nz-input placeholder="input search text"
                                formControlName="search" />
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button class="bg-gray" nzType="default" nzSearch nzSize="large"><span nz-icon
                                    nzType="search"></span></button>
                        </ng-template>
                    </form>
                </div>

                <div nz-col [nzSpan]="6">
                    <nz-select nzSize="large" nzPlaceHolder="Select Role" class="d-block"
                        [(ngModel)]="userMgr.usersRole" (ngModelChange)="userRoleChanage($event)">
                        <nz-option nzValue="" nzLabel="Role - All"></nz-option>
                        <nz-option nzValue="Recruiter" nzLabel="Recruiter"></nz-option>
                        <nz-option nzValue="Hiring Manager" nzLabel="Hiring Manager"></nz-option>
                        <nz-option nzValue="Partner Admin" nzLabel="Partner Admin"></nz-option>
                        <nz-option nzValue="Account Owner" nzLabel="Account Owner"></nz-option>
                    </nz-select>
                </div>
                <div nz-col [nzSpan]="6">
                    <nz-select nzSize="large" nzPlaceHolder="Select Status" class="d-block"
                        [(ngModel)]="userMgr.usersStatus" (ngModelChange)="userStatusChanage($event)">
                        <nz-option nzValue="" nzLabel="Status - All"></nz-option>
                        <nz-option nzValue="true" nzLabel="Active"></nz-option>
                        <nz-option nzValue="false" nzLabel="Inactive"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <nz-spin [nzSpinning]="userMgr.isLoadingUsers">
            <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="14">
                    <div class="table-data">
                        <nz-table [nzFrontPagination]="false" #data [nzData]="userMgr.usersData">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Partner Name</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Status</th>
                                    <th scope="col" nzWidth="50px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of data.data" (click)="selectedUser(data,data.id)"
                                    [class.selected-row]="data.id === partnerId">
                                    <td class="capitalize"><a>{{
                                            data.user_profile?.first_name }} {{
                                            data.user_profile?.last_name }}</a>
                                    </td>
                                    <td class="capitalize-first"><span
                                            *ngIf="data.partner">{{data.partner.partner_name}}</span></td>
                                    <td>{{data.role}}</td>
                                    <td>
                                        <nz-tag class="ico-badge">
                                            <nz-badge [nzColor]="data.is_active ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                            {{ data.is_active ? 'Active' : 'Inactive' }}
                                        </nz-tag>

                                    </td>
                                    <td (click)="$event.stopPropagation()">
                                        <i nz-icon nzType="more" nzTheme="outline" class="icon-action" nz-dropdown
                                            nzTrigger="click" [nzDropdownMenu]="menu"></i>
                                        <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item nz-popconfirm
                                                    nzPopconfirmTitle="Are you sure delete this user?"
                                                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="onDeleteUser(data.id)"
                                                    nzPopconfirmPlacement="topLeft">
                                                    Remove User
                                                </li>
                                                <li nz-menu-item (click)="sendInvite(data.id,data.partner.id)">
                                                    Send Invite
                                                </li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </td>

                                </tr>
                            </tbody>
                        </nz-table>
                        <div class="pagination-center">
                            <nz-pagination *ngIf="userMgr.totalUsers > 10" [nzPageIndex]="1"
                                (nzPageIndexChange)="handlePageIndexChange($event)"
                                [nzTotal]="userMgr.totalUsers"></nz-pagination>
                        </div>

                    </div>
                </div>
                <div nz-col [nzSpan]="10">
                    <div class="user-card" *ngIf="userMgr.usersData.length > 0">
                        <div class="user-card-header">
                            <span class="capitalize">{{userMgr.selectedUser?.user_profile?.first_name}}
                                {{userMgr.selectedUser?.user_profile?.last_name}}</span>
                            <!-- <button nz-button nzType="default" nzSize="large" nzShape="circle"><span nz-icon
                                            nzType="more" nzTheme="outline"></span></button> -->
                        </div>
                        <div class="user-card-subheader">
                            <h4>CLARA Information</h4>
                            <ul>
                                <li>
                                    <span>Role</span>
                                    <span>{{userMgr.selectedUser?.role}}</span>
                                </li>
                                <li>
                                    <span>User ID</span>
                                    <span>{{userMgr.selectedUser?.id}}</span>
                                </li>
                                <li>
                                    <span>Status</span>
                                    <span>
                                        <nz-tag class="ico-badge">
                                            <nz-badge
                                                [nzColor]="userMgr.selectedUser?.is_active ? '#5822d3' : '#CCCCCC'"></nz-badge>
                                            {{ userMgr.selectedUser?.is_active ? 'Active' : 'Inactive' }}
                                        </nz-tag>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="user-card-subheader">
                            <h4>Contact</h4>
                            <ul>
                                <li>
                                    <span>Partner</span>
                                    <span class="capitalize-first"
                                        *ngIf="userMgr.selectedUser?.partner">{{userMgr.selectedUser?.partner?.partner_name}}</span>
                                </li>
                                <li>
                                    <span>Email</span>
                                    <span>{{userMgr.selectedUser?.email}}</span>
                                </li>
                                <li>
                                    <span>Phone Number</span>
                                    <span
                                        *ngIf="userMgr.selectedUser?.user_profile?.phone_number">{{userMgr.selectedUser?.user_profile?.phone_number}}</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </nz-spin>

        <nz-modal [(nzVisible)]="userMgr.isVisibleAddUser" (nzOnCancel)="handleCancel()" [nzFooter]="null"
            nzWidth="400px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2>Invite New User</h2>
                    </div>
                    <form nz-form [formGroup]="addUserForm">

                        <label for="firstName">First Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your First Name!">
                                <input type="text" nzSize="large" nz-input id="firstName"
                                    formControlName="first_name" />
                            </nz-form-control>
                        </nz-form-item>

                        <label for="lastName">Last Name*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input your Last Name!">
                                <input type="text" nzSize="large" nz-input id="lastName" formControlName="last_name" />
                            </nz-form-control>
                        </nz-form-item>

                        <label for="email">Email*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input a valid Email!">
                                <input type="email" nzSize="large" nz-input id="email" formControlName="email" />
                            </nz-form-control>
                        </nz-form-item>

                        <label for="role">Role*</label>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please select at least one Role!">
                                <nz-select nzSize="large" nzPlaceHolder="Select" class="d-block" formControlName="role">
                                    <nz-option nzLabel="Partner Admin" nzValue="Partner Admin"></nz-option>
                                    <nz-option nzLabel="Recruiter" nzValue="Recruiter"></nz-option>
                                    <nz-option nzLabel="Hiring Manager" nzValue="Hiring Manager"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>

                        <div class="custom-footer">
                            <button (click)="submitAddUserForm()" nz-button nzBlock nzSize="large" nzType="primary"
                                class="mb-10" nzShape="round" [disabled]="addUserForm.invalid"><span
                                    *ngIf="userMgr.isAddingUser" nz-icon nzType="loading" nzTheme="outline"></span>
                                Submit Invitation</button>
                            <button nz-button nzType="link" (mousedown)="handleCancel()" nzBlock
                                nzShape="round">Cancel</button>
                        </div>

                    </form>

                </div>
            </ng-container>

        </nz-modal>

    </main>
</div>