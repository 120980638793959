import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  constructor(private http: HttpClient) {}

  getOthersApplications(
    jobObjectId: string,
    segment: string,
    search: string,
    min_score: number,
    max_score: number,
    is_adjust_ai_settings: boolean,
    advance: boolean,
    reject: boolean,
    share: boolean,
    thumbs_down: boolean,
    thumbs_up: boolean,
    answered_followup_questions: boolean,
    locations: string,
    job_req_id: string,
    job_req_name: string,
    yes_no_filters: string,
    scale_filters: string
  ) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const querySearch = search !== '' ? `&search=${search}` : '';
    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobObjectId}/applications/?screening_segment=${segment}&sort_by=index&sort_dir=asc${querySearch}&min_score=${min_score}&max_score=${max_score}&is_adjust_ai_settings=${is_adjust_ai_settings}&advance=${advance}&reject=${reject}&share=${share}&thumbs_down=${thumbs_down}&thumbs_up=${thumbs_up}&answered_followup_questions=${answered_followup_questions}&location=${locations}&job_req_id=${job_req_id}&job_req_name=${job_req_name}&yes_no_filters=${yes_no_filters}&scale_filters=${scale_filters}`,
      {
        headers: header,
      }
    );
  }
  hiringManagerSelection(jobId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobId}/applications/selection/`,
      {
        headers: header,
      }
    );
  }
  screeningStepsDropdown(jobId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/job/${jobId}/screening-steps/`,
      {
        headers: header,
      }
    );
  }
  jobApplicationCounts(jobId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobId}/job-application-counts/`,
      {
        headers: header,
      }
    );
  }
  candidateLocations(jobId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/job/${jobId}/candidate-locations/`,
      {
        headers: header,
      }
    );
  }

  jobDetail(jobObjectId: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/jobs/${jobObjectId}/detail/`,
      {
        headers: header,
      }
    );
  }

  shareApplications(data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL + `deincore/partner/user/application/`,
      data,
      {
        headers: header,
      }
    );
  }
  submitThumbAction(data: any, application_obj_id: string, job_obj_id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL +
        `deincore/partner/applications/${application_obj_id}/${job_obj_id}/thumb`,
      data,
      {
        headers: header,
      }
    );
  }

  getApplicationsRange(jobObjectId: string, range: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobObjectId}/applications/?max_score=${range[1]}&?min_score=${range[0]}`,
      {
        headers: header,
      }
    );
  }
  getApplicationsByUrl(jobObjectId: string, page: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${jobObjectId}/applications/?screening_segment=all&page=${page}`,
      {
        headers: header,
      }
    );
  }

  updateSegment(segment: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.put(
      environment.apiURL + `deincore/partner/applications/segment/`,
      segment,
      {
        headers: header,
      }
    );
  }
  bookmarkApplication(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL + `deincore/candidate/bookmark`,
      { candidate_obj_id: id },
      {
        headers: header,
      }
    );
  }
  removeBookmarkApplication(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.delete(
      environment.apiURL + `deincore/candidate/bookmark?candidate_obj_id=${id}`,
      {
        headers: header,
      }
    );
  }
  jobDetailFollowUpSettings(id: string, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL +
        `deincore/partner/job/${id}/follow-up-questions-status/`,
      data,
      {
        headers: header,
      }
    );
  }
  changeJobStage(id: string, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.patch(
      environment.apiURL +
        `deincore/partner/jobs/standalone/edit-job-stage/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
}
