import { Injectable } from '@angular/core';
import {
  candidates,
  skillMapping,
  metrics,
  candidatesFilters,
} from '../models/users';
import { CandidatesService } from '../Services/candidates.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class CandidatesManagerService {
  candidateData: candidates[] = [];
  candidateFilters: candidatesFilters | undefined;
  bookmarksData: candidates[] = [];
  skillMapping: skillMapping[] = [];
  metrics: metrics | null = null;
  public candidateProfile: candidates | undefined;
  nextPageUrl: string | null = null;
  noMoreBookmarks: boolean = false;
  public applicationProfile!: candidates;
  public isLoading: boolean = false;
  public isLoadingCandidates: boolean = false;
  public isLoadingProfile: boolean = false;
  public totalCandidates: number = 0;
  public totalBookmarks: number = 0;
  public selectedCandidate: candidates | undefined;
  public selectedBookmark: candidates | undefined;

  public page = 1;
  public pageBookmarks = 1;
  public pageSizeCandidate = 18;
  public status: string = '';
  public role: string = '';
  public searchCandidates: string = '';
  public searchBookmarks: string = '';
  public isConfirmResendFollowUpModal: boolean = false;

  constructor(
    public candidateServices: CandidatesService,
    private notification: NzNotificationService
  ) {}
  public getCandidatesSearch(page: number, formValue: any) {
    this.isLoadingCandidates = true;
    this.candidateServices
      .getCandidates(page, formValue)
      .subscribe((c: any) => {
        this.candidateData = c.response.results;
        this.totalCandidates = Object(c.response)['count'];
        this.nextPageUrl = Object(c.response)['next'];
        this.isLoadingCandidates = false;
      });
  }
  public getCandidatesFilters() {
    this.candidateServices.getCandidatesFilters().subscribe((c: any) => {
      this.candidateFilters = c.response;
    });
  }

  public getBookmarksCandidates(page: number, search: string) {
    this.isLoadingCandidates = true;
    this.candidateServices
      .getBookmarksCandidates(page, search)
      .subscribe((c: any) => {
        const latestData = c.results;
        this.bookmarksData = [...this.bookmarksData, ...latestData];
        this.totalBookmarks = Object(c)['totalCount'];
        if (this.totalBookmarks === this.bookmarksData.length) {
          this.noMoreBookmarks = true;
        }
        this.isLoadingCandidates = false;
      });
  }
  public getBookmarksCandidatesSearch(page: number, search: string) {
    this.isLoadingCandidates = true;
    this.candidateServices
      .getBookmarksCandidates(page, search)
      .subscribe((c: any) => {
        this.bookmarksData = c.results;
        this.totalBookmarks = Object(c)['totalCount'];
        if (this.totalBookmarks === this.bookmarksData.length) {
          this.noMoreBookmarks = true;
        }
        this.isLoadingCandidates = false;
      });
  }

  public getAppliationProfile(
    id: string,
    is_adjust_ai_settings: boolean
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingProfile = true;
      this.candidateServices
        .getAppliationProfile(id, is_adjust_ai_settings)
        .subscribe((c: any) => {
          this.applicationProfile = c.response;
          const inputObject = c.response.application_profile.skill_mapping;

          const arrayOfObjects = [];
          for (const key in inputObject) {
            if (
              inputObject.hasOwnProperty(key) &&
              key.includes('_score') &&
              !key.startsWith('max_')
            ) {
              const name = key.replace('_score', '');
              const summaryKey = name + '_summary';
              const maxScoreKey = 'max_' + key;
              if (inputObject[key] !== null && inputObject[key] !== 0) {
                arrayOfObjects.push({
                  name: name,
                  score: inputObject[key],
                  maxScore: inputObject[maxScoreKey],
                  summary: inputObject[summaryKey],
                });
              }
            }
          }
          this.skillMapping = arrayOfObjects;
          this.isLoadingProfile = false;
          resolve();
        });
    });
  }

  public getCandidateProfile(id: any): void {
    this.isLoadingProfile = true;
    this.candidateServices.getCandidateProfile(id).subscribe({
      next: (c: any) => {
        this.candidateProfile = c.response;

        // Check if there are applications and sort them by applied_at
        if (c.response.applications && c.response.applications.length > 0) {
          // Sort the applications by 'applied_at' in descending order and get the metrics of the latest one
          this.metrics = c.response.applications.sort(
            (a: any, b: any) =>
              new Date(b.applied_at).getTime() -
              new Date(a.applied_at).getTime()
          )[0].metrics;
        }

        this.isLoadingProfile = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingProfile = false;
      },
    });
  }
  public removeBookmarkCandidate(id: string) {
    this.candidateServices.removeBookmark(id).subscribe({
      next: () => {
        const candidateIndex = this.candidateData.findIndex(
          (candidate) => candidate._id === id
        );
        if (candidateIndex !== -1) {
          // Update the bookmark status in the candidateData array
          this.candidateData[candidateIndex].is_bookmarked = false;
          this.notification.create(
            'success',
            'Removed Application',
            'The bookmark for the application has been successfully removed'
          );
        } else {
          this.notification.create(
            'error',
            'Error',
            'Candidate not found in the list'
          );
        }
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
      },
    });
  }
  public addBookmarkCandidate(id: string) {
    this.candidateServices.addBookmark(id).subscribe({
      next: () => {
        const candidateIndex = this.candidateData.findIndex(
          (candidate) => candidate._id === id
        );
        if (candidateIndex !== -1) {
          // Update the bookmark status in the candidateData array
          this.candidateData[candidateIndex].is_bookmarked = true;
          this.notification.create(
            'success',
            'Application Bookmarked',
            'Application has been successfully bookmarked!'
          );
        } else {
          this.notification.create(
            'error',
            'Error',
            'Candidate not found in the list'
          );
        }
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
      },
    });
  }
  public reSendFollowUpQuestions(id: string) {
    this.isLoadingProfile = true;
    this.candidateServices.reSendFollowUpQuestions(id).subscribe({
      next: () => {
        this.notification.create('success', 'Questions sent!', '');
        this.isLoadingProfile = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingProfile = false;
      },
    });
  }
  public removeBookmarked(id: string) {
    const removedIndex = this.bookmarksData.findIndex(
      (candidate) => candidate._id === id
    );

    if (removedIndex !== -1) {
      this.bookmarksData.splice(removedIndex, 1);
    } else {
      console.error('Candidate not found in bookmarksData:', id);
      return; // Exit the function if candidate not found
    }

    this.candidateServices.removeBookmark(id).subscribe({
      next: () => {
        this.notification.create(
          'success',
          'Removed Application',
          'The bookmark for the application has been successfully removed'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        console.error('Error removing bookmark:', error);
      },
    });
  }

  public downloadApplicationProfile(id: string, candidate_name: string) {
    const filename = `${candidate_name}-${id}.pdf`;
    this.candidateServices.downloadApplicationProfile(id).subscribe({
      next: (c) => {
        this.downLoadFile(c, filename, 'application/pdf');
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
      },
    });
  }

  private downLoadFile(data: any, filename: string, type: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
