<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Job Settings</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col>
                <div class="heading-flex">
                    <h1>Job Settings</h1>
                    <p><strong>Please note:</strong> Making changes to these settings at the company
                        level
                        will impact any new jobs added to your organization.
                        If you wish to change the requirements for jobs already processed, please do so
                        at
                        the individual job level.</p>
                </div>
                <nz-tabset (nzSelectedIndexChange)="onTabChange($event)" [(nzSelectedIndex)]="jobsMgr.selectedIndex">
                    <nz-tab nzTitle="Job Processing">
                        <div nz-row [nzGutter]="16">
                            <div nz-col>
                                <nz-spin [nzSpinning]="integrationsMgr.isLoading">
                                    <div class="white-box">
                                        <div class="content-box">
                                            <p><strong>Job Processing</strong></p>
                                            <p>When you opt for automatic processing of Candidates, CLARA will handle
                                                all job
                                                applications imported into the system
                                                automatically. If you decide to disable this feature, you'll have the
                                                option to
                                                manually
                                                choose which jobs to process
                                                candidates for.</p>
                                        </div>
                                        <div class="processing-settings">
                                            <div>
                                                <p>Automatic Processing of Candidates</p>
                                            </div>
                                            <nz-switch [(ngModel)]="integrationsMgr.autoJobProcessing"
                                                (ngModelChange)="changeJobProcessingSettings(integrationsMgr.autoJobProcessing)"></nz-switch>
                                        </div>
                                        <div class="light-purple-box" *ngIf="!integrationsMgr.autoJobProcessing">
                                            You have opted out of automatic processing of Candidates. Go to the <a
                                                class="color-purple" routerLink="/jobs">Jobs</a> page to manually choose
                                            which
                                            jobs to process
                                            candidates for.
                                        </div>
                                    </div>

                                </nz-spin>
                            </div>
                            <div nz-col>
                                <nz-spin [nzSpinning]="integrationsMgr.isLoadingFollowupSettings">
                                    <div class="white-box">
                                        <div class="content-box">
                                            <p><strong>Follow up questions by default</strong></p>
                                            <p>This feature allows you to set CLARA follow-up questions to be ON or OFF
                                                by default. It can be customized at the job
                                                level, giving your team the flexibility to enable or disable follow-up
                                                questions for each job as needed.</p>
                                        </div>
                                        <div class="processing-settings" *ngIf="integrationsMgr.followupSettings">
                                            <div>
                                                <p>Follow up questions default position</p>
                                            </div>
                                            <!-- is_enabled parent switch -->
                                            <nz-switch [(ngModel)]="integrationsMgr.followupSettings.is_enabled"
                                                (ngModelChange)="onSettingsChange()" nzSize="default">
                                            </nz-switch>
                                        </div>

                                        <div class="light-purple-box"
                                            *ngIf="integrationsMgr.followupSettings && integrationsMgr.followupSettings.is_enabled">
                                            <p><strong>Additional Insights</strong></p>

                                            <div class="processing-settings">
                                                <div>
                                                    <p>Learning Agility</p>
                                                </div>
                                                <nz-switch
                                                    [(ngModel)]="integrationsMgr.followupSettings.additional_insights.learning_agility"
                                                    (ngModelChange)="onChildSwitchChange()" nzSize="small">
                                                </nz-switch>
                                            </div>

                                            <div class="processing-settings">
                                                <div>
                                                    <p>Critical Thinking</p>
                                                </div>
                                                <nz-switch
                                                    [(ngModel)]="integrationsMgr.followupSettings.additional_insights.critical_thinking"
                                                    (ngModelChange)="onChildSwitchChange()" nzSize="small">
                                                </nz-switch>
                                            </div>

                                            <div class="processing-settings">
                                                <div>
                                                    <p>Distance Traveled</p>
                                                </div>
                                                <nz-switch
                                                    [(ngModel)]="integrationsMgr.followupSettings.additional_insights.distance_traveled"
                                                    (ngModelChange)="onChildSwitchChange()" nzSize="small">
                                                </nz-switch>
                                            </div>
                                            <nz-divider></nz-divider>
                                            <p><strong>General</strong></p>
                                            <div class="processing-settings">
                                                <div>
                                                    <p>General</p>
                                                </div>
                                                <nz-switch [(ngModel)]="integrationsMgr.followupSettings.general"
                                                    (ngModelChange)="onChildSwitchChange()" nzSize="small">
                                                </nz-switch>
                                            </div>
                                        </div>
                                    </div>

                                </nz-spin>
                            </div>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Job Refinement">
                        <div class="white-box">
                            <div class="content-box">
                                <h4>Manage access to job refining.
                                </h4>
                                <p>Note: Job refining will trigger a re-processing of candidates.</p>
                            </div>
                            <div class="text-right mb-16">
                                <button nz-button nzType="default" nzShape="round" nzSize="large"
                                    (click)="openAssignJobModal()">Edit access</button>
                            </div>
                            <nz-spin [nzSpinning]="integrationsMgr.loadingRefineUsers">
                                <nz-table #currentAccess [nzData]="integrationsMgr.jobRefinementUsers"
                                    [nzFrontPagination]="false">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Jobs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of currentAccess.data">
                                            <td class="nowrap">{{ user.name | titlecase }}</td>
                                            <td>
                                                <div class="tags-list">
                                                    <button class="tag-button" *ngIf="isAllJobsAssigned(user)">All
                                                        Jobs</button>

                                                    <ng-container *ngIf="!isAllJobsAssigned(user)">
                                                        <button class="tag-button"
                                                            *ngFor="let job of getGrantedJobs(user)">
                                                            <span class="truncate-100"
                                                                [title]="getJobNameById(user, job.job_obj_id)">
                                                                {{ getJobNameById(user, job.job_obj_id) }}
                                                            </span>
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>

                            </nz-spin>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Job Flow">
                        <div class="white-box">
                            <div class="content-box">
                                <p><strong>The job flow determines the job statuses and screening steps for those jobs
                                        which are created in CLARA.</strong> <br>
                                    Jobs imported through integrations follow the ATS flow.</p>
                            </div>
                            <div class="text-right mb-16">
                                <a routerLink="/jobs/job-flow"><button nz-button nzType="default" nzShape="round"
                                        nzSize="large">Edit
                                    </button></a>
                            </div>
                            <nz-spin [nzSpinning]="jobsMgr.isLoadingJobFlow">
                                <nz-table [nzFrontPagination]="false" nzNoResult=" ">
                                    <thead>
                                        <tr>
                                            <th>Job item</th>
                                            <th>Flow items</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Row for Stages -->
                                        <tr>
                                            <td class="nowrap">Job Statuses</td>
                                            <td>
                                                <div class="tags-list">
                                                    <button class="tag-item"
                                                        *ngFor="let stage of jobsMgr.stages?.statuses">
                                                        {{ stage.job_status_name }}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Row for Screening Steps -->
                                        <tr>
                                            <td class="nowrap">Screening Steps</td>
                                            <td>
                                                <div class="tags-list">
                                                    <button class="tag-item"
                                                        *ngFor="let step of jobsMgr.stages?.screening_steps">
                                                        {{ step.job_stage_name }}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </nz-spin>

                        </div>
                    </nz-tab>
                </nz-tabset>
            </div>
        </div>
        <nz-modal [(nzVisible)]="integrationsMgr.isVisibleAssignJob" nzTitle="Edit Job Refinement Access"
            (nzOnCancel)="handleAssignJobCancel()" [nzFooter]="null" nzWidth="670px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <nz-table #actionTable [nzData]="integrationsMgr.jobRefinementUsers" [nzFrontPagination]="false">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Job(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of actionTable.data">
                                <td>
                                    <div class="checkbox-wrap">
                                        <label>{{ user.name | titlecase }}</label>
                                        <span class="badge-label" *ngIf="isJobRequestedByUser(user)">Access
                                            Request</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="custom-select noselect" (click)="toggleDropdown(user.user_id)">
                                        <div class="custom-placeholder" *ngIf="!hasGrantedJobs(user)">
                                            Select Job(s)
                                        </div>
                                        <div class="tags-list" *ngIf="user.access_status_jobs.length > 0">
                                            <!-- Check if all jobs are selected -->
                                            <ng-container *ngIf="isAllJobsSelected(user); else individualJobs">
                                                <button class="tag-button" nzMode="closeable"
                                                    (click)="onCloseAllJobs(user)">
                                                    <span class="truncate-100">All Jobs</span>
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                </button>
                                            </ng-container>

                                            <!-- Display individual jobs if not all are selected -->
                                            <ng-template #individualJobs>
                                                <button class="tag-button" *ngFor="let job of getGrantedJobs(user)"
                                                    nzMode="closeable" (click)="onCloseJob(user, job.job_obj_id)">
                                                    <span class="truncate-100"
                                                        [title]="getJobNameById(user, job.job_obj_id)">
                                                        {{ getJobNameById(user, job.job_obj_id) }}
                                                    </span>
                                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                                </button>
                                            </ng-template>
                                        </div>

                                        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                                        <div class="custom-list noselect" *ngIf="isDropdownOpen[user.user_id]">
                                            <ng-container *ngIf="user.assigned_jobs.length > 0">
                                                <div class="option-item border-bottom">
                                                    <label nz-checkbox [nzChecked]="isAllJobsSelected(user)"
                                                        [ngModel]="isAllJobsSelected(user)"
                                                        (ngModelChange)="toggleAllJobs(user, $event)">
                                                        All Jobs
                                                    </label>
                                                </div>

                                                <div *ngFor="let job of user.assigned_jobs" class="option-item">
                                                    <div>
                                                        <label nz-checkbox [nzValue]="job.job_obj_id"
                                                            [nzChecked]="isJobGranted(user, job.job_obj_id)"
                                                            [ngModel]="isJobGranted(user, job.job_obj_id)"
                                                            (ngModelChange)="toggleJobSelection(user, job.job_obj_id, $event)"
                                                            [title]="job.job_title">
                                                            <span class="truncate-200">{{ job.job_title }}</span>
                                                        </label>
                                                        <span class="badge-label"
                                                            *ngIf="isJobRequested(user, job.job_obj_id)">Access
                                                            Request</span>
                                                    </div>
                                                    <span class="id-label">{{ job.job_obj_id }}</span>
                                                </div>
                                            </ng-container>
                                            <div class="no-jobs" *ngIf="user.assigned_jobs.length === 0">
                                                No Assign jobs found!
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>


                    <div class="custom-footer">
                        <button class="btn-theme-clara d-block mb-10" (click)="submitForm()">
                            <span *ngIf="integrationsMgr.isLoadingAssignJob" nz-icon nzType="loading"
                                nzTheme="outline"></span>
                            &nbsp;&nbsp; Submit
                        </button>
                        <button nz-button nzType="text" nzSize="large" nzBlock (click)="handleAssignJobCancel()"
                            nzShape="round">
                            Cancel
                        </button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
    </main>
</div>