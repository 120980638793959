import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-application-submitted',
  templateUrl: './application-submitted.component.html',
  styleUrl: './application-submitted.component.scss',
})
export class ApplicationSubmittedComponent {
  jobObjectId: string = '';
  buttonJobApplication: boolean = true;
  buttonAbout: boolean = false;
  isVisibleAboutModal: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sjmMgr: SjmManagerService,
    private sanitizer: DomSanitizer
  ) {}
  openAboutModal(): void {
    this.isVisibleAboutModal = true;
    this.buttonJobApplication = false;
    this.buttonAbout = true;
  }
  handleAboutCancel(): void {
    this.isVisibleAboutModal = false;
    this.buttonJobApplication = true;
    this.buttonAbout = false;
  }
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '<br />') // Replace newlines with <br>
      .replace(/\n/g, '<br />') // Replace literal newlines with <br>
      .replace(/\\f/g, '') // Remove form feed characters
      .replace(/\f/g, '') // Remove literal form feed
      .replace(/\\t/g, '&emsp;') // Replace tab with spaces
      .replace(/\t/g, '&emsp;') // Replace literal tab with spaces
      .replace(/\\u00a0/g, '&nbsp;') // Non-breaking space
      .replace(/\\u0026/g, '&amp;') // Ampersand
      .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>') // Bold for text between **
      .replace(/[^\x00-\x7F]/g, ''); // Remove non-ASCII characters
  }
  colorToLinks(html: string, color: string): SafeHtml {
    const modifiedHtml = html.replace(/<a /g, `<a style="color: ${color};" `);
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtml);
  }

  navigateToJoinClara(): void {
    if (this.sjmMgr?.join_clara_link) {
      window.location.href = this.sjmMgr.join_clara_link;
    } else {
      this.router.navigate(['/login']);
    }
  }
  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
      await this.sjmMgr.getJobInformation(this.jobObjectId);
    });
  }
}
