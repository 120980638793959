<nz-spin [nzSpinning]="candidateMgr.isLoadingCandidates">

    <div class="fiter-form">
        <form [formGroup]="filterForm" (ngSubmit)="onSubmitFilterForm()">
            <div nz-row [nzGutter]="16" nzAlign="bottom">
                <div nz-col [nzSpan]="20">
                    <div nz-row [nzGutter]="16" nzAlign="bottom" *ngIf="candidateMgr.candidateFilters">
                        <div nz-col [nzSpan]="4">
                            <label class="block-label">Jobs Applied</label>
                            <nz-select nzSize="large" nzMode="multiple" nzPlaceHolder="Select Jobs" class="d-block"
                                nzAllowClear formControlName="jobs" [nzMaxTagCount]="1">
                                <nz-option *ngFor="let option of candidateMgr.candidateFilters.jobs"
                                    [nzLabel]="option.title" [nzValue]="option.title">
                                </nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <label class="block-label">Application Stages</label>
                            <nz-select nzSize="large" nzMode="multiple" nzPlaceHolder="Select Stages" class="d-block"
                                nzAllowClear formControlName="application_stages" [nzMaxTagCount]="1">
                                <nz-option *ngFor="let option of candidateMgr.candidateFilters.applications_stages"
                                    [nzLabel]="option.job_stage_name" [nzValue]="option.job_stage_name">
                                </nz-option>
                            </nz-select>
                        </div>
                        <div nz-col>
                            <label class="block-label">Job Status</label>
                            <nz-select nzSize="large" class="d-block" [nzSuffixIcon]="customSuffixIcon"
                                formControlName="job_status" nzPlaceHolder="Select">
                                <nz-option *ngFor="let option of candidateMgr.candidateFilters.jobs_statuses"
                                    [nzLabel]="option" [nzValue]="option">
                                </nz-option>
                            </nz-select>
                        </div>
                        <div nz-col>
                            <label class="block-label">Applied Multiple Jobs</label>
                            <nz-select nzSize="large" class="d-block" [nzSuffixIcon]="customSuffixIcon"
                                formControlName="multiple_jobs" nzPlaceHolder="Select">
                                <nz-option nzValue="yes" nzLabel="Yes"></nz-option>
                                <nz-option nzValue="no" nzLabel="No"></nz-option>
                            </nz-select>
                        </div>

                        <div nz-col>
                            <label class="block-label">From score</label>
                            <nz-select nzSize="large" [nzSuffixIcon]="customSuffixIcon" formControlName="min_score"
                                nzPlaceHolder="Select">
                                <nz-option *ngFor="let score of scoreOptions" [nzValue]="score"
                                    [nzLabel]="score.toString()"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col>
                            <label class="block-label">To score</label>
                            <nz-select nzSize="large" [nzSuffixIcon]="customSuffixIcon" formControlName="max_score"
                                nzPlaceHolder="Select">
                                <nz-option *ngFor="let score of scoreOptions" [nzValue]="score"
                                    [nzLabel]="score.toString()"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col>
                            <div class="checkbox-dashed">
                                <label nz-checkbox formControlName="bookmarked">Bookmarked</label>
                            </div>
                        </div>
                        <div nz-col>
                            <div class="buttons">
                                <button nz-button nzType="primary" nzSize="large" class="mr-8">
                                    Submit
                                </button>
                                <button nz-button nzType="text" nzSize="large" (click)="onResetFilterForm()">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="4">
                    <label class="block-label">Search By Initials or Name</label>
                    <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
                        <input type="text" nzSize="large" nz-input placeholder="Search..." formControlName="search" />
                    </nz-input-group>
                    <ng-template #suffixButton>
                        <button nz-button nzType="default" nzSearch><span nz-icon nzType="search"></span></button>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
    <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" nzSm="12" nzMd="8" nzLg="6" nzXl="4" *ngFor="let data of candidateMgr.candidateData">
            <div class="fancy-card">
                <div class="fancy-card-header" [ngClass]="{'no-bookmarked': !data.is_bookmarked}">
                    <div>
                        <h3>{{data.initials}}</h3>
                        <p>ID {{ data.remote_id ? data.remote_id : data['_id']}}</p>
                    </div>
                    <div class="ico-bookmark" (click)="bookmarkCandidate(data._id,data.is_bookmarked)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.167 1.667h11.666a.833.833 0 0 1 .834.833v15.953a.417.417 0 0 1-.639.353L10 15.026l-6.028 3.78a.417.417 0 0 1-.639-.353V2.5a.833.833 0 0 1 .834-.833z"
                                fill="#E5DBFC" />
                        </svg>

                    </div>
                </div>
                <div class="fancy-card-body">
                    <p class="color-black">Jobs Applied:</p>
                    <p *ngFor="let item of data.jobs; let i = index" class="truncate" [title]="item.title">
                        <ng-container *ngIf="i < 2">
                            {{ item.title }}
                        </ng-container>
                    </p>
                </div>
                <div class="fancy-card-footer">
                    <a (click)="goToCandidatePage(data._id)">See more</a>
                </div>
            </div>
        </div>
    </div>
    <nz-alert *ngIf="!candidateMgr.isLoadingCandidates && candidateMgr.candidateData.length === 0" nzType="info"
        nzMessage="There are no candidates matching your current filter criteria. Try adjusting your filters to find candidates."
        nzDescription="" nzShowIcon>
    </nz-alert>
    <div class="pagination-center">
        <nz-pagination *ngIf="candidateMgr.totalCandidates > 18" [nzPageSize]="18" [nzPageIndex]="1"
            (nzPageIndexChange)="loadMoreCandidate($event)" [nzTotal]="candidateMgr.totalCandidates"></nz-pagination>
    </div>
    <ng-template #customSuffixIcon>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </ng-template>

</nz-spin>