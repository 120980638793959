import { Injectable } from '@angular/core';
import {
  applications,
  assignee_applications,
  applications_overview,
  job_applications_overview,
  assignee_jobs,
  assignee_jobs_lifecycle,
  candidateNotifications,
  notifications,
} from '../models/dashboard';
import { DashboardService } from '../Services/dashboard.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { partners } from '../models/users';
import { active_jobs_analytics } from '../models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardManagerService {
  public isLoadingDashboard = false;
  public isLoadingPartner = false;
  public isLoadingNotification = false;
  partners: partners[] = [];
  public applications!: applications;
  public applicationOverview!: applications_overview;
  public assinee: assignee_applications[] = [];
  public assigneeJobs: assignee_jobs[] = [];
  public assigneeJobsLifecycle: assignee_jobs_lifecycle[] = [];
  public jobApplicationOverveiw: job_applications_overview[] = [];
  public candidateNotifications: candidateNotifications[] = [];
  public notificationsList: notifications[] = [];
  public candidateApplications: candidateNotifications[] = [];
  public timeSaved: { hours: number; minutes: number } = {
    hours: 0,
    minutes: 0,
  };

  avg_job_life!: number;
  active_jobs_analytics!: active_jobs_analytics;
  totalPartners: number = 0;
  partnerPage: number = 1;
  partner_name: string = '';
  constructor(
    public dashboardService: DashboardService,
    private notification: NzNotificationService
  ) {}

  public getPartners(page: number, partner_name: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingDashboard = true;
      this.dashboardService
        .getPartners(page, partner_name)
        .subscribe((c: any) => {
          this.partners = c.response.partners;
          this.totalPartners = Object(c.response)['total_partners'];
          this.isLoadingDashboard = false;
          resolve();
        });
    });
  }

  public getAnalytics(timeframe: string, id: number): Promise<void> {
    this.isLoadingDashboard = true;
    return new Promise<void>((resolve) => {
      this.dashboardService.getAnalytics(timeframe, id).subscribe((c: any) => {
        this.applications = c.response.applications;
        this.assinee = c.response.assignee_applications;
        this.assigneeJobs = c.response.assignee_jobs;
        this.assigneeJobsLifecycle = c.response.assignee_jobs_lifecycle;
        this.applicationOverview = c.response.applications_overview;
        this.avg_job_life = c.response.avg_job_life.toFixed();
        this.active_jobs_analytics = c.response.active_jobs_analytics;

        const timeCount =
          c.response.applications_overview.all.current_count || 0;
        this.calculateTimeSaved(timeCount);
        this.isLoadingDashboard = false;
        resolve();
      });
    });
  }
  public getAnalyticsPartner(timeframe: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingDashboard = true;
      this.dashboardService
        .getAnalyticsPartner(timeframe)
        .subscribe((c: any) => {
          this.applications = c.response.applications;
          this.assinee = c.response.assignee_applications;
          this.assigneeJobs = c.response.assignee_jobs;
          this.assigneeJobsLifecycle = c.response.assignee_jobs_lifecycle;
          this.applicationOverview = c.response.applications_overview;
          this.avg_job_life = c.response.avg_job_life.toFixed();
          this.active_jobs_analytics = c.response.active_jobs_analytics;
          const timeCount =
            c.response.applications_overview.all.current_count || 0;
          this.calculateTimeSaved(timeCount);
          this.isLoadingDashboard = false;
          resolve();
        });
    });
  }
  calculateTimeSaved(delta: number): void {
    const totalMinutes = delta * 2;
    this.timeSaved.hours = Math.floor(totalMinutes / 60);
    this.timeSaved.minutes = totalMinutes % 60;
  }
  public getAnalyticsHR(timeframe: string) {
    this.isLoadingDashboard = true;
    this.dashboardService.getAnalyticsHR(timeframe).subscribe((c: any) => {
      this.jobApplicationOverveiw = c.response.job_applications_overview;
      this.isLoadingDashboard = false;
    });
  }
  public getCandidateDashboard() {
    this.isLoadingDashboard = true;
    this.dashboardService.getCandidateDashboard().subscribe((c: any) => {
      this.candidateNotifications = c.response.notifications;
      this.candidateApplications = c.response.applications;
      this.isLoadingDashboard = false;
    });
  }
  public getNotifications() {
    this.isLoadingDashboard = true;
    this.dashboardService.getNotifications().subscribe((c: any) => {
      this.notificationsList = c.response;
      this.isLoadingDashboard = false;
    });
  }
  public readNotification(id: number) {
    this.isLoadingNotification = true;
    this.dashboardService.readNotification(id).subscribe({
      next: (res: any) => {
        this.getNotifications();
        this.isLoadingNotification = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingNotification = false;
      },
    });
  }
}
