import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
})
export class SubscriptionComponent {
  user: any;
  constructor(
    public auth: AuthenticationService,
    public userMgr: UsersManagerService
  ) {}
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  getFormattedDate(timestamp: number): number {
    return timestamp * 1000; // Convert to milliseconds
  }
  convertCents(number: number): number {
    return parseFloat((number / 100).toFixed(2));
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    if (userRole === 'Partner Admin' || userRole === 'Account Owner') {
      this.userMgr.currentSubscription();
    }
  }
}
