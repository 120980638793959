import { Injectable } from '@angular/core';
import { UsersService } from '../Services/users.service';
import { users, partners, planDetails, plans, metrics } from '../models/users';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UsersManagerService {
  public isLoading: boolean = false;
  public isVisibleAddUser: boolean = false;
  usersData: users[] = [];
  // Partners
  partnersData: partners[] = [];
  isPartnerModal: boolean = false;
  // Pagination Partners
  public totalPartners: number = 0;
  public partnerPage = 1;

  // My Profile
  myProfileData?: users;
  myProfileCandidate!: users;
  metrics: metrics | null = null;
  isProfileModal: boolean = false;
  isChangePasswordModal: boolean = false;

  // Verifing user
  isVerified: boolean = false;
  isTokenExpired: boolean = false;
  verifingUser: boolean = true;

  // Users listing
  public totalUsers: number = 0;
  public usersPage = 1;
  public usersStatus: string = '';
  public usersRole: string = '';
  public search: string = '';
  public isUnsubscribed: boolean = false;
  public isUnsubscribing: boolean = false;
  public isLoadingUsers: boolean = false;
  public isAddingUser: boolean = false;
  public isStripeCustomer: boolean = false;
  public isCustomerChecked = false;
  public selectedUser: users | undefined;
  public planDetails!: planDetails;
  public plans: plans[] = [];
  isEmailSent: boolean = false;
  public isLoadingSubscription: boolean = false;
  public isLoadingCustomer: boolean = false;
  constructor(
    private router: Router,
    public userServices: UsersService,
    private notification: NzNotificationService,
    public auth: AuthenticationService
  ) {}
  public getUsers(
    page: number,
    isActive: string,
    role: string,
    search: string
  ) {
    this.isLoadingUsers = true;
    this.userServices
      .getUsers(page, isActive, role, search)
      .subscribe((c: any) => {
        this.usersData = c.response.results;
        this.selectedUser = this.usersData[0];
        this.totalUsers = Object(c.response)['count'];
        this.isLoadingUsers = false;
      });
  }

  public addUser(formData: users): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isAddingUser = true;
      this.userServices.addUser(formData).subscribe({
        next: (res: any) => {
          this.isEmailSent = true;
          this.notification.create(
            'success',
            'New User Added',
            `${formData.first_name} has been added!`
          );
          this.getUsers(
            this.usersPage,
            this.usersStatus,
            this.usersRole,
            this.search
          );
          this.isAddingUser = false;
          this.isVisibleAddUser = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isAddingUser = false;
        },
      });
    });
  }
  public registerUser(formData: any) {
    this.isAddingUser = true;
    this.userServices.registerUser(formData).subscribe({
      next: (res: any) => {
        this.isEmailSent = true;
        this.notification.create(
          'success',
          'Email Sent!',
          'Please verify your account!'
        );
        this.isAddingUser = false;
        this.router.navigate(['/login']);
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public verifyingUser(token: string) {
    this.userServices.verifyingUser(token).subscribe({
      next: (res: any) => {
        this.isVerified = true;
        this.verifingUser = false;
      },
      error: (error: any) => {
        this.verifingUser = false;
        this.isTokenExpired = true;
      },
    });
  }
  public UnsubscribeFromNewsletter(token: any) {
    this.isUnsubscribing = true;
    this.userServices.UnsubscribeFromNewsletter(token).subscribe({
      next: (res: any) => {
        this.isUnsubscribing = false;
        this.isUnsubscribed = true;
        this.router.navigate(['/']);
      },
      error: (error: any) => {
        this.isUnsubscribing = false;
        this.isUnsubscribed = false;
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
      },
    });
  }
  public myProfile(id: number): void {
    this.isLoading = true;
    this.userServices.myProfile(id).subscribe({
      next: (c: any) => {
        this.myProfileData = c.response;
        this.isProfileModal = false;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
        this.auth.logout();
      },
    });
  }
  public getMyCandidateProfile(): void {
    this.isLoading = true;
    this.userServices.getMyCandidateProfile().subscribe({
      next: (c: any) => {
        this.myProfileCandidate = c.response;

        // Check if there are applications and sort them by applied_at
        if (
          c.response.profile_info?.applications &&
          c.response.profile_info.applications.length > 0
        ) {
          // Sort the applications by 'applied_at' in descending order and get the metrics of the latest one
          this.metrics = c.response.profile_info.applications.sort(
            (a: any, b: any) =>
              new Date(b.applied_at).getTime() -
              new Date(a.applied_at).getTime()
          )[0].metrics;
        }

        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }

  public editProfile(formData: users, id: number): void {
    this.isAddingUser = true;
    this.userServices.editProfile(formData, id).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Updates done',
          'Your account information successfully updated.'
        );
        let user = JSON.parse(localStorage.getItem('user') || '{}');
        this.myProfile(user.id);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(c.response));
        this.isAddingUser = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public changePassword(formData: any): void {
    this.isAddingUser = true;
    this.userServices.changePassword(formData).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Password Updated!',
          'Your password was successfully updated'
        );
        let user = JSON.parse(localStorage.getItem('user') || '{}');
        this.isAddingUser = false;
        this.auth.logout();
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public changePasswordParter(formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isAddingUser = true;
      this.userServices.changePasswordParter(formData).subscribe({
        next: (c: any) => {
          this.notification.create(
            'success',
            'Password Updated!',
            'Your password was successfully updated'
          );
          let user = JSON.parse(localStorage.getItem('user') || '{}');
          this.isAddingUser = false;
          resolve();
          this.auth.logout();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isAddingUser = false;
          reject(error);
        },
      });
    });
  }
  public registerPartnerAndStripIntegration(formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isAddingUser = true;
      this.userServices.changePasswordParter(formData).subscribe({
        next: (c: any) => {
          this.notification.create(
            'success',
            'Password Updated!',
            'Your password was successfully updated'
          );

          this.isAddingUser = false;
          resolve();
          this.auth.logout();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isAddingUser = false;
          reject(error);
        },
      });
    });
  }
  public registerCandidate(formData: any): void {
    this.isAddingUser = true;
    this.userServices.registerCandidate(formData).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Account Created!',
          'Your account is successfully created'
        );
        let user = JSON.parse(localStorage.getItem('user') || '{}');
        this.isAddingUser = false;
        this.auth.logout();
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public removeMyAccount(id: number): void {
    this.isAddingUser = true;
    this.userServices.removeMyAccount(id).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Removed',
          'Removed account successfully!'
        );
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.router.navigate(['/login']);
        this.isAddingUser = false;
        this.auth.logout();
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public removeUser(id: number): void {
    this.userServices.removeUser(id).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Removed',
          'Removed account successfully!'
        );
        const totalItemsOnPage = this.usersData.length;
        if (totalItemsOnPage === 1 && this.usersPage > 1) {
          this.usersPage -= 1;
        }

        this.getUsers(
          this.usersPage,
          this.usersStatus,
          this.usersRole,
          this.search
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public sendInvite(formData: any): void {
    this.isLoadingUsers = true;
    this.userServices.sendInvite(formData).subscribe({
      next: (c: any) => {
        this.notification.create(
          'success',
          'Sent!',
          'Email verification link is sent successfully!'
        );
        this.isLoadingUsers = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingUsers = false;
      },
    });
  }
  public forgotUser(formData: users) {
    this.isAddingUser = true;
    this.userServices.forgotUser(formData).subscribe({
      next: (res: any) => {
        this.notification.create('success', 'Sent', 'Email sent successfully!');
        this.isAddingUser = false;
        this.isEmailSent = true;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
        this.isEmailSent = false;
      },
    });
  }
  public resetPassword(formData: any): void {
    this.isAddingUser = true;
    this.userServices.resetPassword(formData).subscribe({
      next: (res: any) => {
        this.isEmailSent = true;
        this.notification.create(
          'success',
          'Updated',
          'Password updated successfully!'
        );
        this.isAddingUser = false;
        this.router.navigate(['/login']);
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isAddingUser = false;
      },
    });
  }
  public addPartner(formData: users) {
    this.isAddingUser = true;
    this.isLoading = true;
    this.userServices.addUser(formData).subscribe((response: any) => {
      this.notification.create(
        'success',
        'Added',
        'New User has been added successfully!'
      );
      this.getUsers(
        this.usersPage,
        this.usersStatus,
        this.usersRole,
        this.search
      );
      this.isAddingUser = false;

      this.isLoading = false;
    });
  }
  public getPartners(page: number) {
    this.isLoadingUsers = true;
    this.userServices.getPartners(page).subscribe((c: any) => {
      this.partnersData = c.response.results;
      this.totalPartners = Object(c.response)['count'];
      this.isLoadingUsers = false;
    });
  }
  public addNewPartner(formData: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoading = true;
      this.userServices.addNewPartner(formData).subscribe({
        next: (c: any) => {
          this.notification.create(
            'success',
            'Added',
            'Partner has been added and he has been invited to join.'
          );

          this.isLoading = false;
          this.isPartnerModal = false;
          this.getPartners(1);
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
        },
      });
    });
  }
  public deletePartner(id: number): void {
    this.isLoadingUsers = true;
    this.userServices.deletePartner(id).subscribe({
      next: (res: any) => {
        this.notification.create(
          'success',
          'Deleted',
          'Partner deleted successfully!'
        );
        this.isLoadingUsers = false;
        this.getPartners(1);
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingUsers = false;
      },
    });
  }
  public currentSubscription(): void {
    this.isLoadingSubscription = true;
    this.userServices.currentSubscription().subscribe({
      next: async (res: any) => {
        await this.checkStripeCustomer();
        if (this.isStripeCustomer) {
          this.planDetails = res.data;
        }

        this.isLoadingSubscription = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingSubscription = false;
      },
    });
  }
  public checkStripeCustomer(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.isLoadingCustomer = true;
      this.userServices.checkStripeCustomer().subscribe({
        next: (c: any) => {
          this.isStripeCustomer = Object(c.response)['stripe_customer'];
          console.log('customer', this.isStripeCustomer);
          this.isLoadingCustomer = false;
          this.isCustomerChecked = true;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoadingCustomer = false;
          this.isStripeCustomer = false;
          this.isCustomerChecked = true;
        },
      });
    });
  }

  ngOnInit(): void {
    this.isEmailSent = false;
    this.isAddingUser = false;
  }
}
