import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CandidatesService {
  constructor(private http: HttpClient) {}
  getCandidates(page: number, formValue: any) {
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    let params = new HttpParams()
      .set('page', page.toString())
      .set('page_size', '18'); // Fixed parameters

    // Add only non-empty form values to the query parameters
    Object.keys(formValue).forEach((key) => {
      const value = formValue[key];
      if (value !== null && value !== undefined && value !== '') {
        params = params.set(key, value.toString());
      }
    });

    return this.http.get(`${environment.apiURL}deincore/partner/candidates/`, {
      headers: header,
      params: params,
    });
  }
  getCandidatesFilters() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/candidates-filters/`,
      {
        headers: header,
      }
    );
  }
  getAppliationProfile(id: string, is_adjust_ai_settings: boolean) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/partner/applications/${id}/profile/?is_adjust_ai_settings=${is_adjust_ai_settings}`,
      {
        headers: header,
      }
    );
  }
  getCandidateProfile(id: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/candidate/${id}/profile/`,
      {
        headers: header,
      }
    );
  }

  getBookmarksCandidates(page: number, search: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/candidate/bookmarks?page_number=${page}&search=${search}&page_size=18`,
      {
        headers: header,
      }
    );
  }
  removeBookmark(id: string): Observable<any> {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http
      .delete(
        environment.apiURL +
          `deincore/candidate/bookmark?candidate_obj_id=${id}`,
        {
          headers: header,
        }
      )
      .pipe(
        catchError((error) => {
          console.error('Error:', error);
          return error;
        }),
        finalize(() => {
          console.log('HTTP request completed');
        })
      );
  }
  addBookmark(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL + `deincore/candidate/bookmark`,
      { candidate_obj_id: id },
      {
        headers: header,
      }
    );
  }
  reSendFollowUpQuestions(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL + `deincore/candidate/send-email-to-candidate/`,
      { application_obj_id: id },
      {
        headers: header,
      }
    );
  }
  downloadApplicationProfile(id: string) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      'Accept-Encoding': 'gzip,deflate,br',
      responseType: 'blob',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/candidate/application/download?application_object_id=${id}`,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: headers,
      }
    );
  }
}
