import { Component, ViewChild } from '@angular/core';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AssignJobComponent } from 'src/app/components/partials/assign-job/assign-job.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  @ViewChild(AssignJobComponent)
  assignJobModal!: AssignJobComponent;
  addUserForm!: UntypedFormGroup;
  searchFormUser!: UntypedFormGroup;
  isVisibleAddUser: boolean = false;
  isLoading: boolean = false;
  confirmModal?: NzModalRef;
  current = 1;
  user: any;
  partnerId: number = 0;

  constructor(
    public userMgr: UsersManagerService,
    private modal: NzModalService,
    private notification: NzNotificationService,
    private fb: UntypedFormBuilder
  ) {}

  async submitAddUserForm(): Promise<void> {
    if (this.addUserForm.valid) {
      if (this.addUserForm.valid) {
        this.addUserForm.value.role = this.addUserForm.value.role;
        await this.userMgr.addUser(this.addUserForm.value);
        this.addUserForm.reset();
      }
    } else {
      Object.values(this.addUserForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  handlePageIndexChange(pageIndex: number): void {
    this.userMgr.usersPage = pageIndex;
    this.userMgr.getUsers(
      this.userMgr.usersPage,
      this.userMgr.usersStatus,
      this.userMgr.usersRole,
      this.userMgr.search
    );
  }
  userStatusChanage(value: string): void {
    this.userMgr.usersStatus = value;
    this.userMgr.getUsers(
      this.userMgr.usersPage,
      this.userMgr.usersStatus,
      this.userMgr.usersRole,
      this.userMgr.search
    );
  }
  userRoleChanage(value: string): void {
    this.userMgr.usersRole = value;
    this.userMgr.getUsers(
      this.userMgr.usersPage,
      this.userMgr.usersStatus,
      this.userMgr.usersRole,
      this.userMgr.search
    );
  }
  onSearchInputUser(): void {
    this.submitSearchUser();
  }
  submitSearchUser(): void {
    this.userMgr.search = this.searchFormUser.value.search;
    this.userMgr.getUsers(
      this.userMgr.usersPage,
      this.userMgr.usersStatus,
      this.userMgr.usersRole,
      this.userMgr.search
    );
  }
  onDeleteUser(id: number) {
    this.userMgr.removeUser(id);
  }
  sendInvite(id: number, partnerId: number) {
    let formdata = {
      id: id,
      company_id: partnerId,
    };
    this.userMgr.sendInvite(formdata);
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userMgr.getUsers(1, '', '', '');
    this.addUserForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      role: ['', [Validators.required]],
    });
    this.searchFormUser = this.fb.group({
      search: [''],
    });
  }
  selectedUser(user: any, partner_id: number) {
    this.userMgr.selectedUser = user;
    this.partnerId = partner_id;
  }
  showModalAddUser(): void {
    this.userMgr.isVisibleAddUser = true;
  }

  handleCancel(): void {
    this.userMgr.isVisibleAddUser = false;
    this.addUserForm.reset();
  }

  showConfirm(user: string): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: `Do you want to delete this user ${user}`,
      nzContent:
        'The action will revoke access to the platform and will remove trhe user any assigments.',
      nzOkText: 'Remove User',
      nzOnOk: () => {
        this.notification.create(
          'success',
          'User Removed!',
          'User was successfully removed from thier account.'
        );
      },
    });
  }
  showAssignJobModal(): void {
    this.assignJobModal.isVisible = true;
  }

  ngOnDestroy() {
    this.userMgr.usersPage = 1;
    this.userMgr.usersStatus = '';
    this.userMgr.usersRole = '';
    this.userMgr.search = '';
  }
}
